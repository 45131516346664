import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import moment from 'moment';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilterOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import TaskListColumns from '../TaskList/Shared/TaskListColumns';
import UserFunctions from '../User/Register/UserFunctions';

import {
  addCertificatesTableFilter,
  deleteStudents,
  fetchCertificatesTableFilter,
  fetchProduct,
  fetchSettings,
  fetchStages,
  fetchTasks,
  fetchTrainingCenters,
  fetchTrainings,
  updateCertificatesTableFilter,
} from './API/CertificateAPI';
import CertificateActionButtons from './Components/CertificateActionButtonsComponent';
import CertificateSettingsModal from './Components/CertificateSettingsModal';
import GenerateCertificateModal from './Components/GenerateCertificateModal';
import UploadStudentsModal from './Components/UploadStudentsModal';

import './CertificateStyle.scss';

const { RangePicker } = DatePicker;

const notSelectableStages = [
  1, // 1 = Aguardando
  6, // 6 = Reagendar
  11, // 11 = Distrato
  3, // 3 = Confirmado
  4, // 4 = Negado
  6, // 6 = Reagendar
  10, // 10 = Pré-Reservado
  12, // 12 = Reservado
  13, // 13 = Reservado sem confirmação
];

function CertificateView() {
  const [formFilter] = Form.useForm();
  const todayDate = moment().add(-3, 'hour');

  // Modal
  const [isUploadStudentsModalOpen, setIsUploadStudentsModalOpen] = useState(false);
  const [isCertificateSettingsModalOpen, setIsCertificateSettingsModalOpen] = useState(false);
  const [isGenerateCertificateModalOpen, setIsGenerateCertificateModalOpen] = useState(false);

  // Geral
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [filters, setfilters] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [subTableSelectedRowKeys, setSubTableSelectedRowKeys] = useState([]);

  // Options
  const [trainingCenterOptions, setTrainingCenterOptions] = useState([]);
  const [trainingOptions, setTrainingOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [bitrixUserOptions, setBitrixUserOptions] = useState([]);
  const [certificateModelOptions, setCertificateModelOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  // Filtros
  const [certificatesTableFilter, setCertificatesTableFilter] = useState();

  const handleFetchTasks = async (pFilters = filters) => {
    setIsLoading(true);
    setfilters(pFilters);
    const taskFilters = pFilters;

    if (pFilters?.period) {
      const [start, end] = pFilters.period;
      taskFilters.startDate = start;
      taskFilters.endDate = end;
    }

    const tasks = await fetchTasks(taskFilters);

    setTaskList(tasks);

    setIsLoading(false);
  };

  const fetchData = async () => {
    try {
      const [settings, trainingCenters, trainings, stages, product, resCertificatesTableFilter] =
        await Promise.all([
          fetchSettings(),
          fetchTrainingCenters(),
          fetchTrainings(),
          fetchStages(),
          fetchProduct(),
          fetchCertificatesTableFilter(),
        ]);

      setTrainingCenterOptions(trainingCenters);
      setTrainingOptions(trainings);
      setStageOptions(stages);
      setProductOptions(product);
      setCertificatesTableFilter(resCertificatesTableFilter);

      await UserFunctions.fetchBitrixUsers(settings.bitrixWebhookUrl, setBitrixUserOptions);

      setCertificateModelOptions(settings.certificateModelList ?? []);

      await handleFetchTasks(
        resCertificatesTableFilter?.filters || { period: [todayDate, todayDate] }
      );
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar buscar as opções de filtro!');
    }
  };

  const handleDeleteStudents = async () => {
    await deleteStudents(selectedRowKeys, taskList, subTableSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keyList, objectList) => {
      setSelectedRowKeys(keyList);

      const allSubTableKeyList = objectList.flatMap(
        ({ queryStudentList }) => queryStudentList?.map(({ id }) => id) || []
      );

      const deselectedMainRowKeys = selectedRowKeys.filter((key) => !keyList.includes(key));

      const deselectedSubTableKeys = deselectedMainRowKeys.flatMap((deselectedKey) => {
        const record = taskList.find((obj) => obj.id === deselectedKey);
        return record?.queryStudentList?.map(({ id }) => id) || [];
      });

      const newSubTableKeys = subTableSelectedRowKeys.filter(
        (key) => !deselectedSubTableKeys.includes(key)
      );

      const finalSubTableKeys = [...newSubTableKeys, ...allSubTableKeyList];

      setSubTableSelectedRowKeys(finalSubTableKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: notSelectableStages.includes(record.stage?.id),
    }),
  };

  const expandableConfig = {
    expandedRowRender: (record) => (
      <Table
        columns={[
          {
            title: 'Nome',
            dataIndex: 'name',
            width: 300,
          },
          {
            title: 'CPF',
            dataIndex: 'cpf',
            width: 150,
          },
          {
            title: 'RG',
            dataIndex: 'rg',
            width: 150,
          },
          {
            title: 'CNPJ',
            dataIndex: 'cnpj',
            width: 150,
          },
          {
            title: 'Razão Social',
            dataIndex: 'companyName',
            width: 400,
          },
          {
            title: 'Endereço',
            dataIndex: 'address',
          },
        ]}
        dataSource={record.queryStudentList}
        pagination={false}
        showHeader={true}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: subTableSelectedRowKeys,
          onChange: (keys) => {
            setSubTableSelectedRowKeys(keys);

            const subTableTaskId = record.id;
            const isSubTableEmpty = keys.length === 0;

            if (!isSubTableEmpty) {
              if (!selectedRowKeys.includes(subTableTaskId)) {
                setSelectedRowKeys([...selectedRowKeys, subTableTaskId]);
              }
            } else {
              setSelectedRowKeys(selectedRowKeys.filter((key) => key !== subTableTaskId));
            }
          },
          getCheckboxProps: (subRecord) => ({
            disabled: notSelectableStages.includes(subRecord.stage?.id),
          }),
        }}
      />
    ),
    rowExpandable: (record) => record.queryStudentList?.length > 0,
  };

  const handleFilterSave = async () => {
    setIsFilterLoading(true);
    const filterFormValues = formFilter.getFieldsValue();
    const submitData = {};
    submitData.filters = filterFormValues;

    if (certificatesTableFilter) {
      submitData.id = certificatesTableFilter.id;

      await updateCertificatesTableFilter(submitData);
    } else {
      await addCertificatesTableFilter(submitData);
    }
    setIsFilterLoading(false);
  };

  const renderFilter = () => (
    <Form
      form={formFilter}
      disabled={isLoading || isFilterLoading}
      name="TaskListFilter"
      onFinish={handleFetchTasks}
      layout="vertical"
      autoComplete="off"
      initialValues={
        certificatesTableFilter?.filters || {
          period: [todayDate, todayDate],
          responsibles: [],
          stages: [],
          trainingCenterIds: [],
          readyGenerateCertificate: null,
          trainings: [],
          typeTrainings: [],
        }
      }
      style={{ width: '25vw', backgroundColor: 'white', padding: '16px', borderRadius: '8px' }}
    >
      <Form.Item name="period" label="Período">
        <RangePicker format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item label="Centro de Treinamento" name="trainingCenterIds">
        <Select
          options={trainingCenterOptions}
          loading={isLoading}
          placeholder="Selecione um CT"
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16, zIndex: 1050 }}
          showSearch
          allowClear
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(
              values,
              trainingCenterOptions,
              'trainingCenterIds',
              formFilter
            )
          }
        />
      </Form.Item>
      <Form.Item label="Possui Configuração de Certificado" name="readyGenerateCertificate">
        <Select
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16 }}
        />
      </Form.Item>

      <Form.Item label="Responsável" name="responsibles">
        <Select
          options={bitrixUserOptions}
          allowClear
          placeholder="Selecione"
          optionFilterProp="label"
          showSearch
          dropdownStyle={{ borderRadius: 16, zIndex: 1050 }}
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(values, bitrixUserOptions, 'responsibles', formFilter)
          }
        />
      </Form.Item>
      <Form.Item label="Estágio" name="stages">
        <Select
          options={stageOptions}
          allowClear
          placeholder="Selecione"
          optionFilterProp="label"
          showSearch
          dropdownStyle={{ borderRadius: 16, zIndex: 1050 }}
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(values, stageOptions, 'stages', formFilter)
          }
        />
      </Form.Item>
      <Form.Item label="Treinamento" name="trainings">
        <Select
          options={trainingOptions}
          allowClear
          placeholder="Selecione"
          optionFilterProp="label"
          showSearch
          dropdownStyle={{ borderRadius: 16 }}
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(values, trainingOptions, 'trainings', formFilter)
          }
        />
      </Form.Item>
      <Form.Item label="Tipo" name="typeTrainings">
        <Select
          options={[
            { label: 'CT', value: 'CT' },
            { label: 'In Company', value: 'In Company' },
            { label: 'EAD', value: 'EAD' },
          ]}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16 }}
          mode="multiple"
          maxTagCount={3}
        />
      </Form.Item>
      <Row gutter={12}>
        <Col span={8}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            style={{ width: '100%' }}
          >
            Buscar
          </Button>
        </Col>
        <Col span={8}>
          <Button type="default" onClick={handleFilterSave} style={{ width: '100%' }}>
            Salvar
          </Button>
        </Col>
        <Col span={8}>
          <Button
            type="default"
            onClick={() =>
              formFilter.setFieldsValue({
                period: [],
                responsibles: [],
                stages: [],
                trainingCenterIds: [],
                readyGenerateCertificate: null,
                trainings: [],
                typeTrainings: [],
              })
            }
            style={{ width: '100%' }}
          >
            Limpar
          </Button>
        </Col>
      </Row>
    </Form>
  );

  let certificateListColumns = [];
  if (fieldsPermissions) {
    certificateListColumns = TaskListColumns({
      editingIds: null,
      fieldsPermissions,
      columnsSettings: null,
      returnAllColumns: true,
      instructorOptions: null,
      driverOptions: null,
      vehicleOptions: null,
      reasonOptions: null,
      transportCompanyOptions: null,
      trainingCenterOptions,
      formTaskList: null,
      filterByPermissions: true,
    });
    certificateListColumns.unshift({
      title: 'Modelo Certificado',
      dataIndex: ['certificateModel', 'label'],
      width: 300,
    });
    certificateListColumns.unshift({
      width: 30,
      render: (_, record) => {
        const isReady =
          record.certificateModel &&
          record.studentList?.length > 0 &&
          record.queryStudentList?.length > 0;

        if (!isReady || notSelectableStages.includes(record.stage?.id)) {
          return (
            <Tooltip title="Não está pronto para gerar certificado" placement="left">
              <CloseCircleOutlined style={{ color: '#fc5d20', fontSize: 20 }} />
            </Tooltip>
          );
        }
        return (
          <Tooltip title="Pronto para gerar certificado" placement="left">
            <CheckCircleOutlined style={{ color: '#3bb856', fontSize: 20 }} />
          </Tooltip>
        );
      },
    });
  }

  useEffect(() => {
    fetchData();

    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'Certificates') {
          fields.forEach(({ name, access, isRequired, isADM }) => {
            permissions[name] = { access, isRequired, isADM };
          });
        }
      }
    );

    setFieldsPermissions(permissions);
  }, []);

  const shouldDisableCertificateGeneration = () => {
    if (selectedRowKeys.length === 0 || taskList.length === 0) {
      return true;
    }

    const selectedTaskList = taskList.filter(({ id }) => selectedRowKeys.includes(id));

    return selectedTaskList.some((task) => {
      const hasStudents = task.studentList?.length > 0 && task.queryStudentList?.length > 0;
      return !task.certificateModel || !hasStudents;
    });
  };

  if (isLoading || !fieldsPermissions) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  if (!isLoading && fieldsPermissions) {
    return (
      <>
        <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '8px' }}>
            {/* Filtro */}
            <Tooltip title="Filtro">
              <Popover content={renderFilter} trigger="click" overlayClassName="custom-popover">
                <Button type="default" className="filterComponent" icon={<FilterOutlined />} />
              </Popover>
            </Tooltip>
          </div>

          <CertificateActionButtons
            selectedRowKeys={selectedRowKeys}
            subTableSelectedRowKeys={subTableSelectedRowKeys}
            fieldsPermissions={fieldsPermissions}
            shouldDisableCertificateGeneration={shouldDisableCertificateGeneration}
            setIsUploadStudentsModalOpen={setIsUploadStudentsModalOpen}
            setIsCertificateSettingsModalOpen={setIsCertificateSettingsModalOpen}
            setIsGenerateCertificateModalOpen={setIsGenerateCertificateModalOpen}
            handleDeleteStudents={handleDeleteStudents}
          />
        </div>

        <Table
          columns={certificateListColumns}
          rowSelection={rowSelection}
          dataSource={taskList}
          scroll={{ y: '75vh' }}
          size="small"
          rowKey="id"
          pagination={{
            position: ['bottomRight'],
            size: 'default',
            defaultPageSize: 10,
            pageSizeOptions: [10, 20, 30, 50, 100, 200],
            showSizeChanger: true,
            total: taskList?.length ?? 0,
            showTotal: (total) => `${total} treinamentos`,
          }}
          className="certificate-table"
          expandable={{
            ...expandableConfig,
          }}
        />

        {isUploadStudentsModalOpen && (
          <UploadStudentsModal
            setIsOpen={setIsUploadStudentsModalOpen}
            taskList={taskList}
            selectedRowKeys={selectedRowKeys}
          />
        )}

        {isCertificateSettingsModalOpen && (
          <CertificateSettingsModal
            setIsOpen={setIsCertificateSettingsModalOpen}
            certificateModelOptions={certificateModelOptions}
            productOptions={productOptions}
            taskList={taskList}
            selectedRowKeys={selectedRowKeys}
          />
        )}

        {isGenerateCertificateModalOpen && (
          <GenerateCertificateModal
            setIsOpen={setIsGenerateCertificateModalOpen}
            taskList={taskList}
            selectedTaskIds={selectedRowKeys}
            selectedStudentIds={subTableSelectedRowKeys}
          />
        )}
      </>
    );
  }
}

export default CertificateView;

/* eslint-disable no-await-in-loop */
import moment from 'moment';

export const mapEditedRows = (form, currentPage, editingIds) => {
  const formValues = form.getFieldsValue();
  const currentPageIndexList = currentPage.map(({ tablePageIndex }) => tablePageIndex);

  const editedRowsData = formValues.list
    .map((editedValues, index) => {
      if (currentPageIndexList.includes(index)) {
        const fullData = { ...currentPage[index], ...editedValues };
        return fullData;
      }
      return null;
    })
    .filter(({ id }) => editingIds.includes(id));

  return editedRowsData;
};

export const mapSubmitData = async (
  editedRowsData,
  trainingCenterOptions,
  instructorOptions,
  driverOptions,
  transportCompanyOptions,
  vehicleOptions,
  reasonOptions,
  stageOptions
) => {
  const waitingStage = stageOptions?.find(({ id }) => id === 1); // 1 = Aguardando
  const completedStage = stageOptions?.find(({ id }) => id === 5); // 5 = Concluído
  const submitData = [];

  for (let index = 0; index < editedRowsData.length; index += 1) {
    const item = editedRowsData[index];
    const itemData = JSON.parse(JSON.stringify(item));

    if (item.mainInstructor?.userId) {
      const mainInstructor = instructorOptions.find(({ id }) => id === item.mainInstructor.userId);

      itemData.mainInstructor = {
        userId: mainInstructor.id,
        userName: mainInstructor.name,
        stage: waitingStage, // Estágio = Aguardando
        acceptanceDeadline: new Date(moment(item.startDate).add(2, 'hours')),
      };
    }

    if (item.assistantInstructor?.userId) {
      const assistantInstructor = instructorOptions.find(
        ({ id }) => id === item.assistantInstructor.userId
      );

      itemData.assistantInstructor = {
        userId: assistantInstructor.id,
        userName: assistantInstructor.name,
        stage: waitingStage, // Estágio = Aguardando
        acceptanceDeadline: new Date(moment(item.startDate).add(2, 'hours')),
      };
    }

    console.log(item.transportCompany, item.transportCompany?.id);
    console.log(item.mainInstructor, item.mainInstructor?.userId);
    console.log(item.assistantInstructor, item.assistantInstructor?.userId);
    console.log(item.reasonNoBilling, item.reasonNoBilling?.id);
    console.log(item.invoiceIssuer, item.invoiceIssuer?.id);

    if (item.transportCompany?.id) {
      const transportCompany = transportCompanyOptions.find(
        ({ id }) => id === item.transportCompany.id
      );

      itemData.transportCompany = {
        id: transportCompany.id,
        name: transportCompany.commercialName,
      };
    }

    if (item.reasonNoBilling?.id) {
      const reasonNoBilling = reasonOptions.find(({ id }) => id === item.reasonNoBilling.id);

      itemData.reasonNoBilling = reasonNoBilling;
    }

    if (item.invoiceIssuer?.id) {
      const invoiceIssuer = trainingCenterOptions.find(({ id }) => id === item.invoiceIssuer.id);

      itemData.invoiceIssuer = {
        id: invoiceIssuer.id,
        name: invoiceIssuer.commercialName,
      };
    }

    if (item.driverList) {
      itemData.driverUserIdList = item.driverList;
      itemData.driverList = [];

      item.driverList.forEach((driverId) => {
        const driver = driverOptions.find(({ id }) => id === driverId);

        itemData.driverList.push({
          userId: driver.id,
          userName: driver.name,
          stage: waitingStage, // Estágio = Aguardando
          acceptanceDeadline: new Date(moment(item.startDate).add(2, 'hours')),
        });
      });
    }

    if (item.vehicleList) {
      itemData.vehicleList = [];

      item.vehicleList.forEach((vehicleId) => {
        const vehicle = vehicleOptions.find(({ id }) => id === vehicleId);
        itemData.vehicleList.push(vehicle);
      });
    }

    if (item.hasDocumentation) {
      itemData.stage = completedStage; // Estágio = Concluído
    }

    submitData.push(itemData);
  }

  return submitData;
};

const formatValue = (value, inputType) => {
  if (!value) return value;

  switch (inputType) {
    case 'date':
      return moment(value);
    case 'dateTime':
      return moment(value);
    case 'time':
      return moment(moment(value).add(-3, 'hour'));
    default:
      return value;
  }
};

const formatListItem = (item, fieldName) => {
  let value = item;
  fieldName.forEach((field) => {
    if (value) {
      value = value[field];
    }
  });

  return value;
};

export const getCellInitialValue = (record, dataIndex, fieldName, inputType) => {
  let value = record;

  if (inputType === 'driverList' || inputType === 'vehicleList') {
    value = record[dataIndex];

    if (Array.isArray(value)) {
      return value.map((item) => formatListItem(item, fieldName));
    }

    return [];
  }

  if (Array.isArray(fieldName) && fieldName.length > 1) {
    fieldName.forEach((field) => {
      if (value) {
        value = value[field];
      }
    });

    return formatValue(value, inputType);
  }

  value = record[dataIndex];
  return formatValue(value, inputType);
};

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Col, message, Modal, Radio, Row, Table } from 'antd';
import JSZip from 'jszip';
import { Document, Page, pdfjs } from 'react-pdf';

import {
  ArrowLeftOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import {
  deleteStudents,
  generateCertificate,
  generatePreviewCertificate,
} from '../API/CertificateAPI';

import '../../TaskList/Components/ModalStyle.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function GenerateCertificateModal({
  setIsOpen,
  taskList,
  selectedTaskIds,
  selectedStudentIds,
  showTable = false,
}) {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [pdfFiles, setPdfFiles] = useState([]);

  const selectedTaskList = taskList.filter(({ id }) => selectedTaskIds.includes(id));
  const disableMultipleFiles =
    selectedTaskList.length === 1 && selectedTaskList[0].certificateModel.modelType === 2;

  const [isLoading, setIsLoading] = useState(false);
  const [generationType, setGenerationType] = useState(!!disableMultipleFiles);

  const onGenerateCertificate = async () => {
    setIsLoading(true);
    const type = generationType ? 1 : 2;

    await generateCertificate(selectedTaskIds, taskList, selectedStudents, type);
    setIsOpen(false);
  };

  const onPreview = async () => {
    setIsLoading(true);
    try {
      const type = generationType ? 1 : 2;

      const zipBlob = await generatePreviewCertificate(
        selectedTaskIds,
        taskList,
        selectedStudents,
        type
      );

      if (!zipBlob) {
        setIsLoading(false);
        return;
      }

      // Use JSZip to extract files
      const zip = await JSZip.loadAsync(zipBlob);
      const pdfFilePromises = [];

      zip.forEach((_, zipEntry) => {
        if (!zipEntry.dir && zipEntry.name.endsWith('.pdf')) {
          const pdfPromise = zipEntry
            .async('blob')
            .then((fileData) => ({ name: zipEntry.name, blob: fileData }));
          pdfFilePromises.push(pdfPromise);
        }
      });

      const pdfFiles = await Promise.all(pdfFilePromises);

      const pdfFilesWithPageCount = pdfFiles.map((file) => ({
        ...file,
        numPages: null, // Initialize numPages to null
      }));

      setPdfFiles(pdfFilesWithPageCount);
      setIsPreviewing(true);
    } catch (error) {
      console.error('Error extracting zip file:', error);
      message.error('Erro ao extrair o arquivo ZIP!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteStudents = async () => {
    await deleteStudents(selectedTaskIds, taskList, selectedStudents);
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
    },
    {
      title: 'Razão Social',
      dataIndex: 'commercialName',
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedStudents,
    onChange: (keyList) => {
      setSelectedStudents(keyList);
    },
  };

  useEffect(() => {
    if (showTable) {
      const studentIdList = taskList[0].queryStudentList.map(({ id }) => id);
      setSelectedStudents(studentIdList);
    } else {
      setSelectedStudents(selectedStudentIds);
    }
  }, []);

  return (
    <Modal
      title="Gerar Certificado"
      open={true}
      width={isPreviewing ? '90vw' : '50vw'}
      className="generate-certificate-modal"
      closable={!isLoading}
      maskClosable={!isLoading}
      onCancel={() => {
        if (isPreviewing) {
          setIsPreviewing(false);
          setPdfFiles([]);
        } else {
          setIsOpen(false);
        }
      }}
      footer={
        isPreviewing
          ? [
              <Button
                type="primary"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  setIsPreviewing(false);
                  setPdfFiles([]);
                }}
                disabled={isLoading}
              >
                Voltar
              </Button>,
            ]
          : [
              <div className="generate-certificate-footer-wrapper" style={{ width: '100%' }}>
                <div>
                  <Button
                    type="ghost"
                    key="preview"
                    icon={<EyeOutlined />}
                    onClick={onPreview}
                    disabled={isLoading || selectedStudents.length === 0}
                    loading={isLoading}
                  >
                    Pré-Visualizar
                  </Button>
                  {showTable && (
                    <Button
                      type="ghost"
                      key="preview"
                      icon={<DeleteOutlined />}
                      onClick={handleDeleteStudents}
                      disabled={isLoading || selectedStudents.length === 0}
                      loading={isLoading}
                    >
                      Remover Alunos
                    </Button>
                  )}
                </div>

                <div>
                  <Button
                    type="primary"
                    key="send"
                    icon={<DownloadOutlined />}
                    onClick={onGenerateCertificate}
                    disabled={isLoading || selectedStudents.length === 0}
                    loading={isLoading}
                    style={{ backgroundColor: '#5cb85c', color: 'white', border: 'none' }}
                  >
                    Gerar
                  </Button>
                  <Button
                    type="ghost"
                    key="cancel"
                    onClick={() => setIsOpen(false)}
                    disabled={isLoading}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>,
            ]
      }
    >
      {isPreviewing ? (
        <div
          style={{
            maxHeight: '70vh',
            overflowY: 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {pdfFiles.map((pdfFile, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <h3>{pdfFile.name}</h3>
              <Document
                file={pdfFile.blob}
                onLoadError={(error) => console.error('Error loading PDF:', error)}
                onLoadSuccess={(pdf) => {
                  setPdfFiles((prevFiles) => {
                    const newFiles = [...prevFiles];
                    newFiles[index] = { ...newFiles[index], numPages: pdf.numPages };
                    return newFiles;
                  });
                }}
              >
                {Array.from(new Array(pdfFile.numPages), (el, pageIndex) => (
                  <Page
                    key={`page_${pageIndex + 1}`}
                    pageNumber={pageIndex + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                ))}
              </Document>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Row gutter={[24]}>
            <Col span={24}>
              <Radio.Group
                options={[
                  { label: 'Múltiplos Arquivos', value: false, disabled: disableMultipleFiles },
                  { label: 'Arquivo Único', value: true },
                ]}
                value={generationType}
                optionType="default"
                onChange={(val) => setGenerationType(val.target.value)}
              />
            </Col>
          </Row>

          {showTable && (
            <Table
              columns={columns}
              dataSource={taskList[0].queryStudentList}
              pagination={false}
              rowKey="id"
              rowSelection={rowSelection}
              style={{ marginTop: 15 }}
            />
          )}
        </>
      )}
    </Modal>
  );
}

export default GenerateCertificateModal;

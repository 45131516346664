/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Badge, Tooltip } from 'antd';
import { Accordion, Draggable } from 'devextreme-react';

import { ShareAltOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import ScheduleUtils from '../Schedule/ScheduleUtils';

import DraggableTask from './DraggableTask';

let timeout = null;
let currentTaskId = null;

export default function Task({ itemData, schedulerRef, taskRescheduling }) {
  const [dataSourceTasks, setDataSourceTasks] = useState();

  const onItemDragStart = (e) => {
    if (
      e.fromData.stage.id !== 1 && // Aguardando
      e.fromData.stage.id !== 6 && // Reagendar
      !taskRescheduling
    ) {
      e.cancel = true;
    }
    e.itemData = e.fromData;
  };

  const onItemDragEnd = (e) => {
    if (e.toData) {
      e.cancel = true;
    }
  };

  const onItemClick = (taskId) => {
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
      }, 300);
    } else if (currentTaskId === taskId) {
      clearTimeout(timeout);
      timeout = null;
      const clickedTask = itemData.tasks?.find(({ id }) => id === taskId);
      clickedTask.isReadOnly = true;
      schedulerRef.current.instance.showAppointmentPopup(clickedTask);
    }
    currentTaskId = taskId;
  };

  const dragRender = ({ itemData }) => <DraggableTask itemData={itemData} />;

  useEffect(() => {
    setDataSourceTasks(Utils.groupBy(itemData.tasks, 'classId'));
  }, []);

  const getProductNameComplement = (product) => {
    if (product?.complement && product?.complement !== '') {
      return `${product?.name}${` - ${product?.complement}` ?? ''}`;
    }

    return product?.name;
  };

  const getProductName = (product) => {
    const isCustomProduct = ScheduleUtils.getCustomProductList().includes(product?.id);

    if (isCustomProduct) {
      return product.complement ? product.complement : product.name;
    }

    return getProductNameComplement(product);
  };

  return (
    <Accordion
      className="class-accordion"
      dataSource={Object.keys(dataSourceTasks ?? {})}
      collapsible
      multiple
      itemTitleRender={(classId) => {
        const [task] = dataSourceTasks[classId];

        return (
          <h2>
            {task.counterPracticalDays}{' '}
            {task.cardTitle?.replace(`D${task.trainingDay}`, task.dealId)}
          </h2>
        );
      }}
      itemRender={(classId) =>
        dataSourceTasks[classId].map((task) => {
          const productName = getProductName(task.product);

          return (
            <Draggable
              key={task.id}
              className="dx-task-card"
              style={{
                backgroundColor: Utils.hexToRgbA(task.stage.color, 0.2),
              }}
              clone={true}
              group="appointmentsGroup"
              data={task}
              dragRender={dragRender}
              onDragStart={onItemDragStart}
              onDragEnd={onItemDragEnd}
            >
              <div className="task-content" onClick={() => onItemClick(task.id)}>
                <div
                  className="left-bar"
                  style={{
                    backgroundColor: task.stage.color,
                  }}
                />
                <h5 className="class-id">{task.cardTitle}</h5>
                <p className="card-text">{productName}</p>
                {/* <p className="card-text">{task.dealClientCommercialName}</p> */}
                <p className="card-text">Alunos: {task.qtyStudentsPerClass}</p>
                <p className="card-text">{task.trainingType}</p>
                <p className="card-text">{task.stage.name}</p>
                {task.startDateFormatted && <p className="card-text">{task.startDateFormatted}</p>}
                {task.quoteOrDealUpdated && (
                  <Badge className="updated-badge" status="default" text="Atualizado" />
                )}
                <div className="right-bar">
                  {task.sharedTraining && (
                    <Tooltip placement="right" title="Treinamento Compartilhado">
                      <ShareAltOutlined />
                    </Tooltip>
                  )}
                </div>
              </div>
            </Draggable>
          );
        })
      }
    />
  );
}

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import { addTaskColumnsSettings, updateTaskColumnsSettings } from '../API/TaskListApi';

import './ModalStyle.scss';

const defaultFields = {
  dealId: true,
  cardTitle: true,
  product: true,
  stage: true,
  startDate: true,
  startHour: true,
  endHour: true,
  group: true,
  quoteResponsible: true,
  dealOportunity: true,
  dealClient: true,
};

function ColumnsSettingsModal({ columns, setIsVisible, columnsSettings, fieldsPermissions }) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();
  const [searchText, setSearchText] = useState('');

  // Carrega as colunas disponíveis
  let columnsArray = columns({
    editingIds: null,
    fieldsPermissions,
    columnsSettings: null,
    returnAllColumns: true,
    instructorOptions: null,
    driverOptions: null,
    vehicleOptions: null,
    reasonOptions: null,
    transportCompanyOptions: null,
    trainingCenterOptions: null,
  });

  // Ordena as colunas pelo título em ordem alfabética
  columnsArray = columnsArray.sort((a, b) => a.title.localeCompare(b.title));

  const shouldHide = (indexToFilter) => {
    const titleLower = columnsArray
      .find(({ dataIndex }) => dataIndex === indexToFilter)
      .title.toLowerCase();

    if (searchText !== '') {
      return !titleLower.includes(searchText);
    }
    return false;
  };

  const searchFilter = ({ title }) => {
    const titleLower = title.toLowerCase();

    if (searchText !== '') {
      return titleLower.includes(searchText);
    }
    return titleLower;
  };

  const handleSelectAll = (element) => {
    const allFields = {};

    columnsArray.filter(searchFilter).forEach(({ dataIndex }) => {
      allFields[dataIndex] = element.target.checked;
    });

    form.setFieldsValue(allFields);
  };

  const handleSearch = (element) => {
    const text = element.target.value;
    const textLower = text.toLowerCase();

    setSearchText(textLower);
  };

  const setInitialValues = () => {
    if (columnsSettings && columnsSettings.length > 0) {
      const [{ columns, id }] = columnsSettings;
      const splitColumns = columns.split(',');
      const mappedInitialValues = splitColumns.reduce((acc, column) => {
        acc[column] = true;
        return acc;
      }, {});

      form.setFieldsValue({ ...mappedInitialValues, idSettingsColumns: id });
    } else {
      form.setFieldsValue(defaultFields);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const values = form.getFieldsValue();
      const activeColumns = Object.entries(values)
        .filter(([, value]) => value)
        .map(([key]) => key)
        .join(',');
      const submitData = { columns: activeColumns };

      if (columnsSettings.length > 0) {
        submitData.id = values.idSettingsColumns;
        await updateTaskColumnsSettings(submitData);
      } else {
        await addTaskColumnsSettings(submitData);
      }

      message.success('Atualização de configuração das colunas concluída!');
      window.location.reload(); // Recarrega a página
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar salvar as configurações de colunas!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setInitialValues();
  }, []);

  console.log(columnsArray);

  return (
    <Modal
      title="Personalizar exibição de colunas"
      open={true}
      width="60%"
      className="training-list-columns-settings-modal"
      confirmLoading={isLoading}
      onCancel={() => setIsVisible(false)}
      footer={[
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Checkbox onChange={handleSelectAll}>Selecionar tudo</Checkbox>
          <div style={{ display: 'flex', gap: 8 }}>
            {/* Salvar */}
            <Button
              style={{
                backgroundColor: '#5cb85c', // Verde
                color: 'white',
                border: 'none',
              }}
              onClick={handleSubmit}
            >
              Salvar
            </Button>

            {/* Cancelar */}
            <Button
              style={{ backgroundColor: '#fff', color: '#333', border: 'none' }}
              onClick={() => setIsVisible(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>,
      ]}
    >
      <Form
        form={form}
        disabled={isLoading}
        name="ColumnSettingsForm"
        onFinish={handleSubmit}
        layout="inline"
        autoComplete="off"
      >
        <Form.Item name="idSettingsColumns" key="idSettingsColumns" style={{ display: 'none' }}>
          <Input />
        </Form.Item>

        <Row gutter={[24]} style={{ width: '100%' }}>
          <Col span={8}>
            <Form.Item key="search">
              <Input
                placeholder="Econtrar campo"
                disabled={isLoading}
                onKeyUp={handleSearch}
                suffix={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ borderTopColor: '#91919173' }} orientation="left" />

        <Row gutter={[24]}>
          {columnsArray.map((column) => (
            <Col span={8} style={{ display: shouldHide(column.dataIndex) ? 'none' : 'flex' }}>
              <Form.Item
                name={column.dataIndex}
                key={column.dataIndex}
                valuePropName="checked"
                style={{ display: 'flex' }}
              >
                <Checkbox value={column.dataIndex}>{column.title}</Checkbox>
              </Form.Item>
            </Col>
          ))}
        </Row>

        <Divider style={{ borderTopColor: '#91919173' }} orientation="left" />
      </Form>
    </Modal>
  );
}

export default ColumnsSettingsModal;

/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { message } from 'antd';
import moment from 'moment';

import Utils from '../../../Assets/Scripts/Utils';
import ScheduleUtils from '../../../Components/Schedule/ScheduleUtils';
import { api } from '../../../Services/axiosService';

const mountProductName = (product) => {
  const customProductList = ScheduleUtils.getCustomProductList();
  const isCustomProduct = customProductList.includes(product?.id);

  if (isCustomProduct) {
    return product.complement ? product.complement : product.name;
  }

  return product.name;
};

export const fetchTasks = async (filters = null) => {
  try {
    const url = '/Task/TaskList';
    const response = await api.post(url, filters);
    const tasks = response.data;

    const mappedTasks = tasks
      .map((task, index) => ({
        ...task,
        tablePageIndex: index,
        key: task.id,
        name: mountProductName(task.product),
      }))
      .sort((a, b) => a.id - b.id);

    return mappedTasks;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Treinamentos!');
    return [];
  }
};

export const fetchSettings = async () => {
  try {
    const response = await api.get(`/Settings?id=1`);
    return Utils.decryptSettings(response.data);
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar as Configurações!');
    return [];
  }
};

export const fetchStages = async () => {
  try {
    const response = await api.get(`/Stage`);
    return [
      { label: 'Selecionar Todos', value: 'selectAll' },
      ...response.data.map((stage) => ({
        ...stage,
        label: stage.name,
        value: stage.id,
      })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Estágios!');
    return [];
  }
};

export const fetchProduct = async () => {
  try {
    const response = await api.get(`/Product`);
    return [
      ...response.data.map((product) => ({
        ...product,
        label: product.name,
        value: product.id,
      })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Produtos!');
    return [];
  }
};

export const fetchTrainingCenters = async () => {
  try {
    const response = await api.get(
      `/TrainingCenter?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Centro de Treinamento`
    );
    return [
      { label: 'Selecionar Todos', value: 'selectAll' },
      ...response.data
        .filter(
          (tc) =>
            tc.ctQtySlots > 0 ||
            tc.inCompanyQtySlots > 0 ||
            tc.eadQtySlots > 0 ||
            tc.serviceQtySlots > 0
        )
        .sort((a, b) => b.ctQtyslots - a.ctQtyslots)
        .map((tc) => ({ ...tc, value: tc.id, label: tc.commercialName })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Centros de Treinamento!');
    return [];
  }
};

export const fetchTrainings = async () => {
  try {
    const response = await api.get('/Trainings');
    return [
      { label: 'Selecionar Todos', value: 'selectAll' },
      ...response.data.map((trainings) => ({ label: trainings.name, value: trainings.id })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao buscar os Grupos de Treinamento!');
    return [];
  }
};

export const generateCertificate = async (
  selectedRowKeys,
  taskList,
  subTableSelectedRowKeys,
  generationType = 1
) => {
  // Map selected tasks to the CertificateTableRowModel structure
  const certificateTableModel = selectedRowKeys
    .map((taskId) => {
      const task = taskList.find((t) => t.id === taskId);
      if (!task || !task.queryStudentList) return null;

      // Filter and collect student IDs for the current task
      const studentIds = task.queryStudentList
        .filter((student) => subTableSelectedRowKeys.includes(student.id))
        .map((student) => student.id);

      return {
        TaskId: taskId,
        StudentIdList: studentIds,
      };
    })
    .filter(Boolean); // Filter out any null entries

  if (certificateTableModel.length === 0) {
    message.warn('Oops. Nenhum registro válido para gerar certificado!');
    return;
  }

  const certificateEndpointModel = {
    CertificateTableRowList: certificateTableModel,
    GenerationType: generationType,
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_HOST}/Task/GenerateCertificate`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conecta__token')}`,
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: generationType === 1 ? 'application/pdf' : 'application/zip', // Accept header should expect a ZIP file format
      },
      body: JSON.stringify(certificateEndpointModel),
    });

    if (response.status < 200 || response.status >= 300) {
      throw new Error('Failed to generate certificates');
    }

    const blob = await response.blob();
    const fileExtension = generationType === 1 ? 'pdf' : 'zip';
    const fileName = `Certificates_${moment(new Date()).format('YYYY-MM-DD')}.${fileExtension}`; // Correct filename and extension
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    message.success('Download do certificado realizado com sucesso!');
  } catch (error) {
    console.error('Error generating certificates:', error);
    message.error('Oops. Algo deu errado ao tentar baixar o certificado!');
  }
};

export const deleteStudents = async (selectedRowKeys, taskList, subTableSelectedRowKeys) => {
  // Map selected tasks to the CertificateTableRowModel structure
  const certificateTableModel = selectedRowKeys
    .map((taskId) => {
      const task = taskList.find((t) => t.id === taskId);
      if (!task || !task.queryStudentList) return null;

      // Filter and collect student IDs for the current task
      const studentIds = task.queryStudentList
        .filter((student) => subTableSelectedRowKeys.includes(student.id))
        .map((student) => student.id);

      return {
        StudentIdList: studentIds,
      };
    })
    .filter(Boolean); // Filter out any null entries

  if (certificateTableModel.length === 0) {
    message.warn('Oops. Nenhum registro selecionado!');
    return;
  }

  const certificateEndpointModel = {
    CertificateTableRowList: certificateTableModel,
  };

  try {
    const url = '/Task/DeleteStudents';
    await api.post(url, certificateEndpointModel).then(() => {
      message.success('Alunos removidos com sucesso!');
    });

    window.location.reload();
  } catch (error) {
    console.error('Erro ao remover alunos:', error);
    message.error('Oops. Algo deu errado ao tentar remover os alunos!');
  }
};

export const generatePreviewCertificate = async (
  selectedTaskIds,
  taskList,
  selectedStudents,
  generationType = 1
) => {
  const certificateTableModel = selectedTaskIds
    .map((taskId) => {
      const task = taskList.find((t) => t.id === taskId);
      if (!task || !task.queryStudentList) return null;

      const studentIds = task.queryStudentList
        .filter((student) => selectedStudents.includes(student.id))
        .map((student) => student.id);

      return {
        TaskId: taskId,
        StudentIdList: studentIds,
      };
    })
    .filter(Boolean);

  if (certificateTableModel.length === 0) {
    message.warn('Oops. Nenhum registro válido para gerar a pré-visualização!');
    return null;
  }

  const certificateEndpointModel = {
    CertificateTableRowList: certificateTableModel,
    GenerationType: generationType,
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_HOST}/Task/GenerateCertificatePreview`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conecta__token')}`,
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/zip',
      },
      body: JSON.stringify(certificateEndpointModel),
    });

    if (!response.ok) {
      throw new Error('Oops. Algo deu errado ao tentar gerar a pré-visualização!');
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error generating certificate preview:', error);
    message.error('Oops. Algo deu errado ao tentar gerar a pré-visualização!');
    return null;
  }
};

export const fetchCertificatesTableFilter = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;
    const filterType = 'certificatesTable';

    const response = await api.get(
      `/ScreenFilter?filters[0].Field=UserId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${userId}&filters[0].Field=Type&filters[0].Condition=EQUAL&filters[0].Value=${filterType}`
    );

    if (response.data?.length > 0) {
      const [filter] = response.data;
      filter.filters = JSON.parse(filter.filters);

      if (filter.filters.period) {
        const periodStart = filter.filters?.period[0];
        const periodEnd = filter.filters?.period[1];
        filter.filters.period = [moment(periodStart), moment(periodEnd)];
      }

      return filter;
    }

    return null;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os filtros!');
    return [];
  }
};

export const addCertificatesTableFilter = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;
    submitData.type = 'certificatesTable';
    submitData.filters = JSON.stringify(submitData.filters);

    const response = await api.post('/ScreenFilter', submitData);
    message.success('Filtros salvos com sucesso!');

    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar salvar os filtros!');
    return [];
  }
};

export const updateCertificatesTableFilter = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;
    submitData.type = 'certificatesTable';
    submitData.filters = JSON.stringify(submitData.filters);

    const response = await api.put('/ScreenFilter', submitData);
    message.success('Filtros salvos com sucesso!');

    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar atualizar os filtros!');
    return [];
  }
};

/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
import React from 'react';
import { Button, message, Popconfirm } from 'antd';
import axios from 'axios';
import { createRoot } from 'react-dom/client';

import {
  DeleteOutlined,
  FilePdfOutlined,
  SettingOutlined,
  SnippetsOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';
import { deleteFileDocuments } from '../../Services/firebaseService';

import CustomInstructorSelect from './CustomInstructorSelect';
import TaskCommentFunctions from './TaskCommentFunctions';

export default class ScheduleUtils {
  static getCustomProductList() {
    return [
      '19852', // Elaboração de AVCB
      '19858', // Emissão de 2ª Via de Documentos - CT
      '324774', // Emissão de 2ª Via de Documentos - In Company
      '19902', // Laudo
      '19882', // Palestra SIPAT
      '19906', // Produto Personalizado - CT
      '310844', // Produto Personalizado - In Company
      '19884', // Renovação de AVCB
      '19886', // Serviço de Emissão de ART - CT
      '324776', // Serviço de Emissão de ART - In Company
      '211350', // Transporte
      '19900', // Visita Técnica - CT
      '324778', // Visita Técnica - In Company
    ];
  }

  static isBlocked(date, workDays) {
    return !workDays.split(',').includes(`${date.getDay()}`); // Gambi porque domingo no projeto é 7 e não zero :)
  }

  static cellBreakTime(date, breakTime) {
    const hour = date.getHours();
    return (
      hour >= new Date(breakTime.breakStart).getHours() &&
      hour < new Date(breakTime.breakFinish).getHours()
    );
  }

  static isBreakTime(startDate, endDate, breakTime) {
    const thisStart = new Date(
      new Date(breakTime.breakStart).setDate(new Date(startDate).getDate())
    );
    const thisEnd = new Date(new Date(breakTime.breakFinish).setDate(new Date(endDate).getDate()));

    return !(
      (thisStart >= new Date(startDate) && thisStart <= new Date(endDate)) ||
      (thisEnd >= new Date(startDate) && thisEnd <= new Date(endDate))
    );
  }

  static isDisableDate(date, workDays) {
    return ScheduleUtils.isBlocked(date, workDays);
  }

  static isBlockedDay(date, blockedDates) {
    const localeDate = date.toLocaleDateString();
    return blockedDates.filter(({ blockedDay }) => blockedDay === localeDate).length > 0;
  }

  static isValidAppointment(component, appointmentData, workDays) {
    const startDate = new Date(appointmentData.startDate);
    const endDate = new Date(appointmentData.endDate);
    const cellDuration = component?.option('cellDuration');
    return ScheduleUtils.isValidAppointmentInterval(startDate, endDate, cellDuration, workDays);
  }

  static isValidAppointmentInterval(startDate, endDate, cellDuration, workDays) {
    const edgeEndDate = new Date(endDate.getTime() - 1);

    if (!ScheduleUtils.isValidAppointmentDate(edgeEndDate, workDays)) {
      return false;
    }

    const durationInMs = cellDuration * 60 * 1000;
    const date = startDate;
    while (date <= endDate) {
      if (!ScheduleUtils.isValidAppointmentDate(date, workDays)) {
        return false;
      }
      const newDateTime = date.getTime() + durationInMs - 1;
      date.setTime(newDateTime);
    }

    return true;
  }

  static isValidAppointmentDate(date, workDays) {
    return !ScheduleUtils.isBlocked(date, workDays);
  }

  static getBreakTime(startHour, endHour, ct) {
    const interval = new Date(ct.breakFinish).getHours() - new Date(ct.breakStart).getHours();

    const breakStartMonth = new Date(ct.breakStart).setMonth(new Date(startHour).getMonth());
    const breakStartDate = new Date(breakStartMonth).setDate(new Date(startHour).getDate());
    const breakStartHour = new Date(breakStartDate).setFullYear(new Date(startHour).getFullYear());
    const breakEndMonth = new Date(ct.breakFinish).setMonth(new Date(endHour).getMonth());
    const breakEndDate = new Date(breakEndMonth).setDate(new Date(endHour).getDate());
    const breakEndHour = new Date(breakEndDate).setFullYear(new Date(endHour).getFullYear());

    return {
      interval,
      breakStartHour,
      breakEndHour,
    };
  }

  static convertDateToToday(hour, convertTo) {
    const startMonth = new Date(hour).setMonth(new Date(convertTo).getMonth());
    const convertedDate = new Date(startMonth).setDate(new Date(convertTo).getDate());
    const convertedHour = new Date(convertedDate).setFullYear(new Date(convertTo).getFullYear());

    return new Date(convertedHour);
  }

  static translateFlag(flag) {
    if (flag) {
      return 'Verdadeiro';
    }
    return 'Falso';
  }

  static async sendPushNotification(task, event, eventSource, receiverId, hasRedirect = true) {
    try {
      let titleSource = '';
      let title = '';
      let body = '';
      let redirectUrl = '';
      const eventSourceFormatted = eventSource.charAt(0).toUpperCase() + eventSource.slice(1);

      switch (eventSourceFormatted) {
        case 'CT':
          titleSource = 'Centro de Treinamento';
          redirectUrl = `/Agenda/CentroTreinamento/OpenTraining/${task.id}`;
          break;
        case 'MainInstructor':
          titleSource = 'Instrutor';
          redirectUrl = `/Agenda/CentroTreinamento/OpenTraining/${task.id}`;
          break;
        case 'AssistantInstructor':
          titleSource = 'Instrutor Auxiliar';
          redirectUrl = `/Agenda/Instrutor/OpenTraining/${task.id}`;
          break;
        case 'Driver':
          titleSource = 'Motorista';
          redirectUrl = `/Agenda/Motorista/OpenTraining/${task.id}`;
          break;
        default:
          break;
      }

      switch (event) {
        case 'TaskDenied':
          title = `Treinamento Negado ${titleSource}`;
          body = `O ${titleSource} (${task[eventSource]?.userName}) negou o treinamento (${task.cardTitle}). Vincule um novo ${titleSource}!`;
          break;
        case 'TaskReceived':
          title = 'Treinamento Recebido';
          body = `Novo treinamento (${task.cardTitle}) recebido. Você pode clicar no botão de redirecionamento para ir até a agenda!`;
          break;
        case 'TaskRemoved':
          title = 'Treinamento Cancelado';
          body = `O treinamento (${task.cardTitle}) foi cancelado e removido da sua agenda!`;
          break;
        default:
          break;
      }

      const data = {
        sourceId: receiverId,
        sourceEvent: eventSource,
        notification: {
          userId: receiverId,
          title,
          body,
          redirectUrlPathWeb: hasRedirect ? redirectUrl : null,
          redirectUrlPathMobile: hasRedirect ? `Agenda` : null,
        },
      };

      await api.post('/Notification', data).then((res) => res);
    } catch (error) {
      Utils.logError(error);
      message.error('Erro ao enviar notificação!');
    }
  }

  static createTime(hours, minutes) {
    return new Date(new Date().setHours(hours, minutes, 0, 0));
  }

  static async sendPushNotificationToResponsible(task) {
    try {
      const [responsible] = await api
        .get('/User?filters[0].Field=BitrixId&filters[0].Condition=EQUAL&filters[0].Value=156')
        .then((res) => res.data);

      const data = {
        sourceId: responsible?.id,
        sourceEvent: 'Responsible',
        notification: {
          userId: responsible?.id,
          title: `Treinamento Negado`,
          body: `O Centro de Treinamento negou o treinamento (${task.cardTitle}).`,
          redirectUrlPathWeb: `/Agenda/Agendamento/OpenTraining/${task.id}`,
        },
      };

      await api.post('/Notification', data).then((res) => res);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao enviar a notificação ao Responsável do Treinamento!');
    }
  }

  static async sendNotificationToLogistics(task, eventSource, driverData, isAcceptance = true) {
    try {
      let titleSource = '';
      let sourceEvent = '';
      let title = '';
      let body = '';

      switch (eventSource) {
        case 'mainInstructor':
          titleSource = 'Instrutor';
          break;
        case 'assistantInstructor':
          titleSource = 'Instrutor Auxiliar';
          break;
        case 'driver':
          titleSource = 'Motorista';
          break;
        default:
          break;
      }

      if (eventSource === 'driver') {
        if (isAcceptance) {
          title = `Treinamento Aceito ${titleSource}`;
          body = `O ${titleSource} (${driverData?.userName}) aceitou o treinamento (${task.cardTitle}). Você pode clicar no botão de redirecionamento para ir até a Agenda!`;
          sourceEvent = 'DriverTaskAccepted';
        } else {
          title = `Treinamento Negado ${titleSource}`;
          body = `O ${titleSource} (${driverData?.userName}) negou o treinamento (${task.cardTitle}). Você pode clicar no botão de redirecionamento para ir até a Agenda!`;
          sourceEvent = 'DriverTaskDenied';
        }
      } else if (isAcceptance) {
        title = `Treinamento Aceito ${titleSource}`;
        body = `O ${titleSource} (${task[eventSource]?.userName}) aceitou o treinamento (${task.cardTitle}). Você pode clicar no botão de redirecionamento para ir até a Agenda!`;
        sourceEvent = 'InstructorTaskAccepted';
      } else {
        title = `Treinamento Negado ${titleSource}`;
        body = `O ${titleSource} (${task[eventSource]?.userName}) negou o treinamento (${task.cardTitle}). Você pode clicar no botão de redirecionamento para ir até a Agenda!`;
        sourceEvent = 'InstructorTaskDenied';
      }

      const receiversList = await api
        .get('/User?filters[0].Field=Role.Id&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=39')
        .then((res) => res.data);

      for (let i = 0; i < receiversList.length; i += 1) {
        const userData = JSON.parse(localStorage.getItem('conecta__userData') ?? '{}');

        const data = {
          sourceId: userData?.id,
          sourceEvent,
          notification: {
            userId: receiversList[i].id,
            title,
            body,
            redirectUrlPathWeb: `/Agenda/Agendamento/OpenTraining/${task.id}`,
          },
        };

        await api.post('/Notification', data).then((res) => res);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Ocorreu um erro ao notificar a Logística!');
    }
  }

  static async sendTrainingHistory(taskID, taskTitle, taskStage, taskDate) {
    try {
      const data = {
        taskId: taskID,
        taskTitle,
        taskStage,
        date: taskDate,
      };
      await api.post('/TrainingHistory', data).then((res) => res);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar salvar o Histórico de Treinamento!');
    }
  }

  static checkLogisticFormFieldsAccess(fieldsPermissions) {
    const fields = [
      'TransportStopAddress',
      'ReferencePoint',
      'ResponsiblePersonNameLogistic',
      'ResponsiblePersonPhoneLogistic',
      'ResponsiblePersonEmailLogistic',
      'PostalCodeLogistic',
      'StreetLogistic',
      'NumberLogistic',
      'DistrictLogistic',
      'CityLogistic',
      'StateLogistic',
      'ComplementLogistic',
      'InstructorValue',
      'AssistantInstructorValue',
    ];

    return fields.some((field) => fieldsPermissions?.[field]?.access !== 0);
  }

  static checkFinanceFormFieldsAccess(fieldsPermissions) {
    const fields = ['TrainingValue', 'BillingDeadlineDate', 'ReasonNoBilling', 'NFTraining'];

    return fields.some((field) => fieldsPermissions?.[field]?.access !== 0);
  }

  static checkTransportFormFieldsAccess(fieldsPermissions) {
    const fields = ['TransportCompany', 'DriverList', 'VehicleList', 'BoardingHour'];

    return fields.some((field) => fieldsPermissions?.[field]?.access !== 0);
  }

  static checkCertificateFormFieldsAccess(fieldsPermissions) {
    const fields = [
      'GenerateCertificateBtn',
      'ImportStudentListBtn',
      'CertificateTrainingSettingsBtn',
      'NotHaveCertificate',
    ];

    return fields.some((field) => fieldsPermissions?.[field]?.access !== 0);
  }

  static async updateAddressFields(form, postalCode) {
    try {
      const addressData = await axios
        .get(`https://viacep.com.br/ws/${postalCode}/json/`)
        .then((res) => res?.data);

      form.option('formData.streetLogistic', addressData?.logradouro);
      form.option('formData.districtLogistic', addressData?.bairro);
      form.option('formData.cityLogistic', addressData?.localidade);
      form.option('formData.stateLogistic', addressData?.uf);
      form.option('formData.complementLogistic', addressData?.complemento);
    } catch (error) {
      Utils.logError(error);
    }
  }

  static uploadTemplate(
    itemElement,
    updateTask,
    data,
    field,
    fieldPermission,
    setUploadFileType,
    setUploadFileModalOpen
  ) {
    const files = data[field] || [];
    const container = document.createElement('div');
    itemElement.append(container);

    const root = createRoot(container);
    root.render(
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        {/* Botão Para Enviar o Arquivo */}
        {fieldPermission.access === 2 && (
          <Button
            type="default"
            icon={<UploadOutlined />}
            style={{ width: 'fit-content' }}
            onClick={() => {
              setUploadFileType(field);
              setUploadFileModalOpen(true);
            }}
          >
            Enviar Arquivo
          </Button>
        )}

        {files.map((file, index) => (
          <div key={index} style={{ display: 'flex', gap: 10 }}>
            <a href={file.url} target="_blank" className="custom-a" rel="noreferrer">
              {file.label}
            </a>

            {/* Opção de Remover o Arquivo */}
            {fieldPermission.access === 2 && (
              <Popconfirm
                placement="topLeft"
                title="Tem certeza que deseja deletar o arquivo?"
                onConfirm={async () => {
                  await this.deleteAttachmentFromTask(file.name, data.id);

                  data[field] = data[field].filter((_, i) => i !== index);
                  await updateTask({ newData: data, oldData: data });

                  let eventName = '';
                  switch (field) {
                    case 'nfTransportation':
                      eventName = 'NFTransportationDelete';
                      break;
                    case 'nfTraining':
                      eventName = 'NFTrainingDelete';
                      break;
                    case 'initialAttendanceDocList':
                      eventName = 'InitialAttendanceListDelete';
                      break;
                    case 'finalAttendanceDocList':
                      eventName = 'FinalAttendanceListDelete';
                      break;
                    case 'instructorForm':
                      eventName = 'InstructorFormDelete';
                      break;
                    default:
                      break;
                  }
                  await TaskCommentFunctions.addTaskCommentUploadFile(
                    data,
                    eventName,
                    'Scheduling'
                  );
                }}
                okText="Sim"
                okType="danger"
                cancelText="Cancelar"
              >
                <DeleteOutlined style={{ color: '#fc5d20' }} />
              </Popconfirm>
            )}
          </div>
        ))}
      </div>
    );
  }

  static async handleFileUpload(file, updateTask, data, field) {
    // Lógica para fazer upload do arquivo ao servidor e obter a URL
    const uploadedFile = await this.uploadFileToServer(file);
    data[field].push({
      url: uploadedFile.url,
      label: uploadedFile.label,
      name: uploadedFile.name,
    });
    await updateTask({ newData: data, oldData: data });
  }

  static generateGeneralFormFields(
    e,
    fieldsPermissions,
    stages,
    trainingsOptions,
    ctResources,
    setUploadFileModalOpen,
    setUploadFileType,
    combinedInstructorList,
    updateTask
  ) {
    let formItems = [];
    const { isReadOnly } = e.appointmentData;

    formItems = [
      // Campo Produto
      {
        visible: fieldsPermissions?.Product.access !== 0,
        editorOptions: {
          autoResizeEnabled: true,
          disabled: true,
        },
        dataField: 'product.name',
        editorType: 'dxTextArea',
        label: {
          text: 'Produto',
        },
      },
      // Campo Complemento
      {
        visible: fieldsPermissions?.Product.access !== 0,
        editorOptions: {
          autoResizeEnabled: true,
          disabled: true,
        },
        dataField: 'product.complement',
        editorType: 'dxTextArea',
        label: {
          text: 'Complemento',
        },
      },
      // Campo Produto - Complemento
      {
        visible: fieldsPermissions?.Product.access !== 0,
        editorOptions: {
          autoResizeEnabled: true,
          disabled: true,
        },
        dataField: 'productWithComplement',
        editorType: 'dxTextArea',
        label: {
          text: 'Produto - Complemento',
        },
      },
      // Campo Estágio
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Stage.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Stage.access !== 0,
        editorOptions: {
          dataSource: stages,
          displayExpr: 'name',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.Stage.access !== 2,
        },
        dataField: 'stage.id',
        editorType: 'dxSelectBox',
        label: {
          text: 'Estágio',
        },
      },
      // Campo Data
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Date.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Date.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.Date.access !== 2,
          type: 'date',
        },
        dataField: 'startDate',
        editorType: 'dxDateBox',
        label: {
          text: 'Data',
        },
      },
      // Campo Horário Início
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.StartHour.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.StartHour.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.StartHour.access !== 2,
          type: 'time',
          showClearButton: true,
          openOnFieldClick: true,
        },
        dataField: 'startHour',
        editorType: 'dxDateBox',
        label: {
          text: 'Horário Início',
        },
      },
      // Campo Horário Fim
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.EndHour.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.EndHour.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.EndHour.access !== 2,
          type: 'time',
          showClearButton: true,
          openOnFieldClick: true,
        },
        dataField: 'endHour',
        editorType: 'dxDateBox',
        label: {
          text: 'Horário Fim',
        },
      },
      // Campo Recursos
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Resource.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Resource.access !== 0,
        editorOptions: {
          dataSource: ctResources,
          displayExpr: 'trainingType',
          valueExpr: 'trainingTypeId',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.Resource.access !== 2,
        },
        dataField: 'trainingTypeId',
        editorType: 'dxSelectBox',
        label: {
          text: 'Recurso',
        },
      },
      // Campo Instrutor
      {
        dataField: 'mainInstructor.userId',
        label: { text: 'Instrutor' },
        disabled: isReadOnly,
        template: (data, itemElement) => {
          const container = document.createElement('div');
          itemElement.append(container);

          const root = createRoot(container);
          root.render(
            <CustomInstructorSelect
              type="mainInstructor"
              taskData={e.appointmentData}
              options={combinedInstructorList}
              fieldsPermissions={fieldsPermissions}
              value={data.editorOptions.value}
              onChange={(newValue) => {
                data.component.updateData('mainInstructor.userId', newValue);
              }}
              onClear={() => {
                data.component.updateData('mainInstructor', undefined);
              }}
            />
          );
        },
      },
      // Campo Instrutor Auxiliar
      {
        dataField: 'assistantInstructor.userId',
        label: { text: 'Instrutor Auxiliar' },
        disabled: isReadOnly,
        template: (data, itemElement) => {
          const container = document.createElement('div');
          itemElement.append(container);

          const root = createRoot(container);
          root.render(
            <CustomInstructorSelect
              type="assistantInstructor"
              taskData={e.appointmentData}
              options={combinedInstructorList}
              fieldsPermissions={fieldsPermissions}
              value={data.editorOptions.value}
              onChange={(newValue) => {
                data.component.updateData('assistantInstructor.userId', newValue);
              }}
              onClear={() => {
                data.component.updateData('assistantInstructor', undefined);
              }}
            />
          );
        },
      },
      // Campo Valor Instrutor
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.InstructorValue.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.InstructorValue.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.InstructorValue.access !== 2,
          format: {
            type: 'currency',
            currency: 'BRL',
            precision: 2,
          },
          showClearButton: true,
        },
        dataField: 'instructorValue',
        editorType: 'dxNumberBox',
        label: {
          text: 'Valor Instrutor',
        },
      },
      // Campo Valor Instrutor Auxiliar
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.AssistantInstructorValue.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.AssistantInstructorValue.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.AssistantInstructorValue.access !== 2,
          format: {
            type: 'currency',
            currency: 'BRL',
            precision: 2,
          },
          showClearButton: true,
        },
        dataField: 'assistantInstructorValue',
        editorType: 'dxNumberBox',
        label: {
          text: 'Valor Instrutor Auxiliar',
        },
      },
      // Campo de treinamentos
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Group.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Group.access !== 0,
        editorOptions: {
          dataSource: trainingsOptions,
          displayExpr: 'name',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.Group.access !== 2,
        },
        dataField: 'group.id',
        editorType: 'dxSelectBox',
        label: {
          text: 'Treinamentos',
        },
      },
      // Campo Nome do Negócio
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.QuoteTitle.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.QuoteTitle.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.QuoteTitle.access !== 2,
        },
        dataField: 'quoteTitle',
        editorType: 'dxTextBox',
        label: {
          text: 'Nome do Negócio',
        },
      },
      // Campo Transporte Oficial
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TrainingTransportation.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TrainingTransportation.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.TrainingTransportation.access !== 2,
        },
        dataField: 'trainingTransportation',
        editorType: 'dxTextBox',
        label: {
          text: 'Transporte Oficial',
        },
      },
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.QuoteResponsible.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.QuoteResponsible.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.QuoteResponsible.access !== 2,
        },
        dataField: 'quoteResponsible',
        editorType: 'dxTextBox',
        label: {
          text: 'Responsável pelo Negócio',
        },
      },
      // Campo Valor da Oportunidade
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealOportunity.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealOportunity.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealOportunity.access !== 2,
        },
        dataField: 'dealOportunity',
        editorType: 'dxTextBox',
        label: {
          text: 'Valor da Oportunidade',
        },
      },
      // Campo Número do Negócio
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealId.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealId.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealId.access !== 2,
        },
        dataField: 'dealId',
        editorType: 'dxTextBox',
        label: {
          text: 'Número Negócio',
        },
      },
      // Campo Nome do Negócio
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealTitle.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealTitle.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealTitle.access !== 2,
        },
        dataField: 'dealTitle',
        editorType: 'dxTextBox',
        label: {
          text: 'Nome do Negócio',
        },
      },
      // Campo Cliente
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealClient.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealClient.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealClient.access !== 2,
        },
        dataField: 'dealClient',
        editorType: 'dxTextBox',
        label: {
          text: 'Cliente',
        },
      },
      // Campo Empresa Contratada
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealContractedCompany.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealContractedCompany.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealContractedCompany.access !== 2,
        },
        dataField: 'dealContractedCompany',
        editorType: 'dxTextBox',
        label: {
          text: 'Empresa Contratada',
        },
      },
      // Campo Endereço do CT para Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealTrainingCenterAdress.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealTrainingCenterAdress.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealTrainingCenterAdress.access !== 2,
        },
        dataField: 'dealTrainingCenterAddress',
        editorType: 'dxTextBox',
        label: {
          text: 'Endereço do CT para Treinamento',
        },
      },

      // Campo Condição de Pagamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealPaymentCondition.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealPaymentCondition.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealPaymentCondition.access !== 2,
        },
        dataField: 'dealPaymentCondition',
        editorType: 'dxTextBox',
        label: {
          text: 'Condição de Pagamento',
        },
      },
      // Campo Forma de Pagamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealPaymentForm.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealPaymentForm.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealPaymentForm.access !== 2,
        },
        dataField: 'dealPaymentForm',
        editorType: 'dxTextBox',
        label: {
          text: 'Forma de Pagamento',
        },
      },
      // Campo Ordem de Compra Obrigatoria
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.ObrigatoryBuyOrder.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.ObrigatoryBuyOrder.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.ObrigatoryBuyOrder.access !== 2,
        },
        dataField: 'obrigatoryBuyOrder',
        editorType: 'dxTextBox',
        label: {
          text: 'Ordem de Compra Obrigatoria',
        },
      },
      // Campo Data Limite para Fatarumento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealEndDateInvoice.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealEndDateInvoice.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealEndDateInvoice.access !== 2,
        },
        dataField: 'dealEndDateInvoice',
        editorType: 'dxTextBox',
        label: {
          text: 'Data Limite para Faturamento Oficial',
        },
      },
      // Campo Retenção Tributária
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealTributaryRetention.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealTributaryRetention.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealTributaryRetention.access !== 2,
        },
        dataField: 'dealTributaryRetention',
        editorType: 'dxTextBox',
        label: {
          text: 'Retenção Tributária',
        },
      },
      // Campo Cliente Priotário
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealPrioritaryClient.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealPrioritaryClient.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealPrioritaryClient.access !== 2,
        },
        dataField: 'dealPrioritaryClient',
        editorType: 'dxTextBox',
        label: {
          text: 'Cliente Priotário',
        },
      },
      // Campo Obeservação Específica
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealClientObservation.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealClientObservation.access !== 0,
        editorOptions: {
          autoResizeEnabled: true,
          disabled: fieldsPermissions?.DealClientObservation.access !== 2,
        },
        dataField: 'dealClientObservation',
        editorType: 'dxTextArea',
        label: {
          text: 'Obeservação Específica',
        },
      },
      // Campo Forma de Pagamento Negociada
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealPaymentFormNegotiated.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealPaymentFormNegotiated.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealPaymentFormNegotiated.access !== 2,
        },
        dataField: 'dealPaymentFormNegotiated',
        editorType: 'dxTextBox',
        label: {
          text: 'Forma de Pagamento Negociada',
        },
      },
      // Campo Condição de Pagamento Negociada
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealPaymentConditionNegotiated.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealPaymentConditionNegotiated.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealPaymentConditionNegotiated.access !== 2,
        },
        dataField: 'dealPaymentConditionNegotiated',
        editorType: 'dxTextBox',
        label: {
          text: 'Condição de Pagamento Negociada',
        },
      },
      // Campo Grupo de Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Group.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Group.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.Group.access !== 2,
        },
        dataField: 'group.name',
        editorType: 'dxTextBox',
        label: {
          text: 'Grupo de Treinamento',
        },
      },
      // Campo Quantidade de Turmas
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.QtyClass.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.QtyClass.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.QtyClass.access !== 2,
        },
        dataField: 'qtyClass',
        editorType: 'dxTextBox',
        label: {
          text: 'Quantidade de Turmas',
        },
      },
      // Campo Alunos por Turma
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.QtyStudentsPerClass.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.QtyStudentsPerClass.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.QtyStudentsPerClass.access !== 2,
        },
        dataField: 'qtyStudentsPerClass',
        editorType: 'dxTextBox',
        label: {
          text: 'Alunos por Turma',
        },
      },
      // Campo Alunos Treinados
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.StudentsTrained.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.StudentsTrained.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.StudentsTrained.access !== 2,
        },
        dataField: 'qtyStudentsTrained',
        editorType: 'dxTextBox',
        label: {
          text: 'Alunos Treinados',
        },
      },
      // Campo Alunos Transportados
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.StudentsTransported.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.StudentsTransported.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.StudentsTransported.access !== 2,
        },
        dataField: 'qtyStudentsTransported',
        editorType: 'dxTextBox',
        label: {
          text: 'Alunos Transportados',
        },
      },
      // // Campo Duração Prevista
      // {
      //   validationRules: [
      //     {
      //       type: 'custom',
      //       validationCallback: ({ value }) => {
      //         if (fieldsPermissions?.ExpctedDuration.isRequired) {
      //           return value !== '';
      //         }

      //         return true;
      //       },
      //       message: 'Campo obrigatório',
      //     },
      //   ],
      //   visible: fieldsPermissions?.ExpctedDuration.access !== 0,
      //   editorOptions: {
      //     disabled: fieldsPermissions?.ExpctedDuration.access !== 2,
      //   },
      //   dataField: 'durationHours',
      //   editorType: 'dxTextBox',
      //   label: {
      //     text: 'Duração Prevista (h)',
      //   },
      // },
      // Campo Centro de Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TrainingCenter.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TrainingCenter.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.TrainingCenter.access !== 2,
        },
        dataField: 'trainingCenter',
        editorType: 'dxTextBox',
        label: {
          text: 'Centro de Treinamento',
        },
      },
      // Campo Data do Agendamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DateScheduling.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DateScheduling.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DateScheduling.access !== 2,
          type: 'date',
        },
        dataField: 'dateScheduling',
        editorType: 'dxDateBox',
        label: {
          text: 'Data do Agendamento',
        },
      },
      // Campo Lista de Presença Inicial
      {
        visible: fieldsPermissions?.InitialAttendanceList.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.InitialAttendanceList.access !== 2,
        },
        disabled: isReadOnly,
        template: (templateData, itemElement) =>
          this.uploadTemplate(
            itemElement,
            updateTask,
            e.appointmentData,
            'initialAttendanceDocList',
            fieldsPermissions?.InitialAttendanceList,
            setUploadFileType,
            setUploadFileModalOpen
          ),
        label: {
          text: 'Lista de Presença Inicial',
        },
      },
      // Campo Lista de Presença Final
      {
        visible: fieldsPermissions?.FinalAttendanceList.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.FinalAttendanceList.access !== 2,
        },
        disabled: isReadOnly,
        template: (templateData, itemElement) =>
          this.uploadTemplate(
            itemElement,
            updateTask,
            e.appointmentData,
            'finalAttendanceDocList',
            fieldsPermissions?.FinalAttendanceList,
            setUploadFileType,
            setUploadFileModalOpen
          ),
        label: {
          text: 'Lista de Presença Final',
        },
      },
      // Formulario do Instrutor
      {
        visible: fieldsPermissions?.InstructorForm.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.InstructorForm.access !== 2,
        },
        disabled: isReadOnly,
        template: (templateData, itemElement) =>
          this.uploadTemplate(
            itemElement,
            updateTask,
            e.appointmentData,
            'instructorForm',
            fieldsPermissions?.InstructorForm,
            setUploadFileType,
            setUploadFileModalOpen
          ),
        label: {
          text: 'Formulário do Instrutor',
        },
      },
      // Campo Pedido de Compra
      {
        visible: fieldsPermissions?.DealBuyOrder.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DealBuyOrder.access !== 2,
        },
        template: () => {
          if (
            e.appointmentData.downloadDealBuyOrder &&
            e.appointmentData.downloadDealBuyOrder !== ''
          ) {
            return `<a href='${e.appointmentData.downloadDealBuyOrder}' target="_blank" class="custom-a">Abrir</a>`;
          }
          return '<p style="color: orange">Pendente</p>';
        },
        label: {
          text: 'Pedido de Compra',
        },
      },
      // Campo Termo de Aceite
      {
        visible: fieldsPermissions?.AcceptTerm1.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.AcceptTerm1.access !== 2,
        },
        template: () => {
          if (
            e.appointmentData.downloadAcceptTerm1 &&
            e.appointmentData.downloadAcceptTerm1 !== ''
          ) {
            return `<a href='${e.appointmentData.downloadAcceptTerm1}' target="_blank" class="custom-a">Abrir</a>`;
          }
          return '<p style="color: orange">Pendente</p>';
        },
        label: {
          text: 'Termo de Aceite',
        },
      },
      // Campo Termo de Aceite Assinado
      {
        visible: fieldsPermissions?.AcceptTerm2.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.AcceptTerm2.access !== 2,
        },
        template: () => {
          if (
            e.appointmentData.downloadAcceptTerm2 &&
            e.appointmentData.downloadAcceptTerm2 !== ''
          ) {
            return `<a href='${e.appointmentData.downloadAcceptTerm2}' target="_blank" class="custom-a">Abrir</a>`;
          }
          return '<p style="color: orange">Pendente</p>';
        },
        label: {
          text: 'Termo de Aceite Assinado',
        },
      },
      // Data de Recebimento do Termo Assinado
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DateUploadAcceptTerm2.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DateUploadAcceptTerm2.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DateUploadAcceptTerm2.access !== 2,
          type: 'date',
        },
        dataField: 'dateUploadAcceptTerm2',
        editorType: 'dxDateBox',
        label: {
          text: 'Data de Recebimento do Termo Assinado',
        },
      },
      // Campo Documentação Enviada
      {
        visible: fieldsPermissions?.HasDocumentation.access !== 0,
        editorOptions: {
          disabled:
            fieldsPermissions?.HasDocumentation.access !== 2 ||
            !e.appointmentData.finalAttendanceDocList ||
            e.appointmentData.finalAttendanceDocList?.length === 0,
        },
        dataField: 'hasDocumentation',
        editorType: 'dxCheckBox',
        label: {
          text: 'Documentação Enviada',
        },
      },
      // Campo Aceita Encaixe
      {
        visible: fieldsPermissions?.AcceptSharedTraining.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.AcceptSharedTraining.access !== 2,
        },
        dataField: 'acceptSharedTraining',
        editorType: 'dxCheckBox',
        label: {
          text: 'Aceita Encaixe',
        },
      },
      // Campo Lista de Presença Verificada
      {
        visible: fieldsPermissions?.AttendanceListChecked.access !== 0,
        editorOptions: {
          disabled:
            fieldsPermissions?.AttendanceListChecked.access !== 2 ||
            !e.appointmentData.finalAttendanceDocList,
        },
        dataField: 'attendanceListChecked',
        editorType: 'dxCheckBox',
        label: {
          text: 'Lista de Presença Verificada',
        },
      },
      // Campo Produtos do Negócio
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.QuoteProducts.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.QuoteProducts.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.QuoteProducts.access !== 2,
          height: 120,
          value: e.appointmentData.quoteProducts,
          onInitialized(e) {
            e.component._$element.addClass('scrollable-readonly');
          },
        },
        dataField: 'quoteProducts',
        editorType: 'dxTextArea',
        label: {
          text: 'Produtos do Negócio',
        },
      },
      // Campo Observação Negócio
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DealNotes.isRequired) {
                return value !== '';
              }
              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.DealNotes.access !== 0,
        editorOptions: {
          readOnly: fieldsPermissions?.DealNotes.access !== 2,
          height: 120,
          onInitialized: (e) => {
            e.component._$element.addClass('scrollable-readonly');
          },
        },
        dataField: 'dealNotes',
        editorType: 'dxTextArea',
        label: {
          text: 'Observação Negócio',
        },
      },

      // Campo Observação Comercial
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.CommercialNotes.isRequired) {
                return value !== '';
              }
              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.CommercialNotes.access !== 0,
        editorOptions: {
          readOnly: fieldsPermissions?.CommercialNotes.access !== 2,
          height: 120,
          onInitialized(e) {
            e.component._$element.addClass('scrollable-readonly');
          },
        },
        dataField: 'commercialNotes',
        editorType: 'dxTextArea',
        label: {
          text: 'Observação Comercial',
        },
      },

      // Campo Observação Logística
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.LogisticsNotes.isRequired) {
                return value !== '';
              }
              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.LogisticsNotes.access !== 0,
        editorOptions: {
          readOnly: fieldsPermissions?.LogisticsNotes.access !== 2, // Alterado para readOnly
          height: 120,
          onInitialized(e) {
            e.component._$element.addClass('scrollable-readonly');
          },
        },
        dataField: 'logisticsNotes',
        editorType: 'dxTextArea',
        label: {
          text: 'Observação Logística',
        },
      },

      // Campo Observação CT
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.CtNotes.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.CtNotes.access !== 0,
        editorOptions: {
          readOnly: fieldsPermissions?.CtNotes.access !== 2,
          height: 120,
          onInitialized(e) {
            e.component._$element.addClass('scrollable-readonly');
          },
        },
        dataField: 'ctNotes',
        editorType: 'dxTextArea',
        label: {
          text: 'Observação CT',
        },
      },
      // Campo Observação Instrutor
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.InstructorNotes.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.InstructorNotes.access !== 0,
        editorOptions: {
          readOnly: fieldsPermissions?.InstructorNotes.access !== 2,
          height: 120,
          onInitialized(e) {
            e.component._$element.addClass('scrollable-readonly');
          },
        },
        dataField: 'instructorNotes',
        editorType: 'dxTextArea',
        label: {
          text: 'Observação Instrutor',
        },
      },
    ];
    return formItems;
  }

  static generateLogisticsFormFields(
    e,
    form,
    fieldsPermissions,
    updateTask,
    setUploadFileType,
    setUploadFileModalOpen
  ) {
    const {
      namePersonResponsibleConfirmation,
      numberResponsibleConfirmation,
      responsibleEmailConfirmation,
      responsiblePersonNameLogistic,
      responsiblePersonPhoneLogistic,
      responsiblePersonEmailLogistic,
    } = e.appointmentData;

    const { isReadOnly } = e.appointmentData;

    if (namePersonResponsibleConfirmation && namePersonResponsibleConfirmation !== '') {
      if (!responsiblePersonNameLogistic || responsiblePersonNameLogistic === '') {
        form.option('formData.responsiblePersonNameLogistic', namePersonResponsibleConfirmation);
      }
    }
    if (numberResponsibleConfirmation && numberResponsibleConfirmation !== '') {
      if (!responsiblePersonPhoneLogistic || responsiblePersonPhoneLogistic === '') {
        form.option('formData.responsiblePersonPhoneLogistic', numberResponsibleConfirmation);
      }
    }
    if (responsibleEmailConfirmation && responsibleEmailConfirmation !== '') {
      if (!responsiblePersonEmailLogistic || responsiblePersonEmailLogistic === '') {
        form.option('formData.responsiblePersonEmailLogistic', responsibleEmailConfirmation);
      }
    }

    return [
      {
        dataField: 'advance24h',
        editorType: 'dxTextBox',
        label: { text: 'Quando Avançado 24 horas Hibrido (Endereço Teoria)' },
        visible: fieldsPermissions?.Advance24h.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.Advance24h.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.Advance24h.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'transportStopAddress',
        editorType: 'dxTextBox',
        label: { text: 'Endereço de Parada Transporte' },
        visible: fieldsPermissions?.TransportStopAddress.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.TransportStopAddress.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.TransportStopAddress.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'referencePoint',
        editorType: 'dxTextBox',
        label: { text: 'Ponto de Referência' },
        visible: fieldsPermissions?.ReferencePoint.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.ReferencePoint.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.ReferencePoint.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'responsiblePersonNameLogistic',
        editorType: 'dxTextBox',
        label: { text: 'Nome Responsável' },
        visible: fieldsPermissions?.ResponsiblePersonNameLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.ResponsiblePersonNameLogistic.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.ResponsiblePersonNameLogistic.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'responsiblePersonPhoneLogistic',
        editorType: 'dxTextBox',
        label: { text: 'Telefone Responsável' },
        visible: fieldsPermissions?.ResponsiblePersonPhoneLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.ResponsiblePersonPhoneLogistic.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.ResponsiblePersonPhoneLogistic.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'responsiblePersonEmailLogistic',
        editorType: 'dxTextBox',
        label: { text: 'E-mail Responsável' },
        visible: fieldsPermissions?.ResponsiblePersonEmailLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.ResponsiblePersonEmailLogistic.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              // Se for obrigatório e não estiver preenchido considera inválido
              if (
                fieldsPermissions?.ResponsiblePersonEmailLogistic.isRequired &&
                (!value || value === '')
              ) {
                return false;
              }

              // Considera campo vazio como válido
              if (!value || value === '') {
                return true;
              }

              // Separa e-mails por ";" e valida cada um deles
              const emails = value.split(';').map((email) => email.trim());
              return emails.every((email) => {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
              });
            },
            message:
              'Por favor, insira um ou mais e-mails válidos separados por ponto e vírgula (;)',
          },
          {
            type: 'custom',
            validationCallback: ({ value }) => !/[,\//:]/.test(value),
            message: 'Utilize apenas ponto e vírgula (;) para separar os e-mails.',
          },
        ],
      },
      {
        dataField: 'postalCodeLogistic',
        editorType: 'dxTextBox',
        label: { text: 'CEP' },
        visible: fieldsPermissions?.PostalCodeLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.PostalCodeLogistic.access !== 2,
          mask: '00000-000',
          maskRules: { X: /[02-9]/ },
          maskInvalidMessage: 'CEP inválido',
          useMaskedValue: true,
          onValueChanged: async (e) => {
            const postalCode = e?.value?.replace('-', '');
            if (postalCode && postalCode?.length === 8) {
              await this.updateAddressFields(form, postalCode);
            }
          },
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.PostalCodeLogistic.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'streetLogistic',
        editorType: 'dxTextBox',
        label: { text: 'Rua' },
        visible: fieldsPermissions?.StreetLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.StreetLogistic.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.StreetLogistic.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'numberLogistic',
        editorType: 'dxTextBox',
        label: { text: 'Número' },
        visible: fieldsPermissions?.NumberLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.NumberLogistic.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.NumberLogistic.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'districtLogistic',
        editorType: 'dxTextBox',
        label: { text: 'Bairro' },
        visible: fieldsPermissions?.DistrictLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.DistrictLogistic.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.DistrictLogistic.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'cityLogistic',
        editorType: 'dxTextBox',
        label: { text: 'Cidade' },
        visible: fieldsPermissions?.CityLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.CityLogistic.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.CityLogistic.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'stateLogistic',
        editorType: 'dxTextBox',
        label: { text: 'Estado' },
        visible: fieldsPermissions?.StateLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.StateLogistic.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.StateLogistic.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'complementLogistic',
        editorType: 'dxTextBox',
        label: { text: 'Complemento' },
        visible: fieldsPermissions?.ComplementLogistic.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.ComplementLogistic.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.ComplementLogistic.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Refund.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Refund.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.Refund.access !== 2,
          format: {
            type: 'currency',
            currency: 'BRL',
            precision: 2,
          },
          showClearButton: true,
        },
        dataField: 'refund',
        editorType: 'dxNumberBox',
        label: {
          text: 'Reembolso',
        },
      },
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TransportValue.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TransportValue.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.TransportValue.access !== 2,
          format: {
            type: 'currency',
            currency: 'BRL',
            precision: 2,
          },
          showClearButton: true,
        },
        dataField: 'transportValue',
        editorType: 'dxNumberBox',
        label: {
          text: 'Valor Transporte',
        },
      },
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.AmountPaidTransportation.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.AmountPaidTransportation.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.AmountPaidTransportation.access !== 2,
          format: {
            type: 'currency',
            currency: 'BRL',
            precision: 2,
          },
          showClearButton: true,
        },
        dataField: 'amountPaidTransportation',
        editorType: 'dxNumberBox',
        label: {
          text: 'Valor Pago Transporte',
        },
      },
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.ConfirmationDate.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.ConfirmationDate.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.ConfirmationDate.access !== 2,
          type: 'date',
        },
        dataField: 'confirmationDate',
        editorType: 'dxDateBox',
        label: {
          text: 'Data de Confirmação',
        },
      },
      {
        dataField: 'confirmedBy',
        editorType: 'dxTextBox',
        label: { text: 'Confirmado por' },
        visible: fieldsPermissions?.ConfirmedBy.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.ConfirmedBy.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.ConfirmedBy.isRequired ? value !== '' : true,
            message: 'Campo obrigatório',
          },
        ],
      },
      {
        dataField: 'nameResponsibleConfirmation',
        editorType: 'dxTextBox',
        label: { text: 'Nome Responsável Confirmação' },
        visible: fieldsPermissions?.NameResponsibleConfirmation.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.NameResponsibleConfirmation.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.NameResponsibleConfirmation.isRequired ? value !== '' : true,
            message: 'Nome do Responsável pela Confirmação',
          },
        ],
      },
      {
        dataField: 'emailResponsibleConfirmation',
        editorType: 'dxTextBox',
        label: { text: 'E-mail Responsável Confirmação' },
        visible: fieldsPermissions?.EmailResponsibleConfirmation.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.EmailResponsibleConfirmation.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              // Se for obrigatório e não estiver preenchido considera inválido
              if (
                fieldsPermissions?.EmailResponsibleConfirmation.isRequired &&
                (!value || value === '')
              ) {
                return false;
              }

              // Considera campo vazio como válido
              if (!value || value === '') {
                return true;
              }

              // Separa e-mails por ";" e valida cada um deles
              const emails = value.split(';').map((email) => email.trim());
              return emails.every((email) => {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
              });
            },
            message:
              'Por favor, insira um ou mais e-mails válidos separados por ponto e vírgula (;)',
          },
          {
            type: 'custom',
            validationCallback: ({ value }) => !/[,\//:]/.test(value),
            message: 'Utilize apenas ponto e vírgula (;) para separar os e-mails.',
          },
        ],
      },
      {
        dataField: 'phoneResponsibleConfirmation',
        editorType: 'dxTextBox',
        label: { text: 'Telefone Responsável Confirmação' },
        visible: fieldsPermissions?.PhoneResponsibleConfirmation.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.PhoneResponsibleConfirmation.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.PhoneResponsibleConfirmation.isRequired ? value !== '' : true,
            message: 'Telefone do responsável pela Confirmação',
          },
        ],
      },
      {
        visible: fieldsPermissions?.NFTransportation.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.NFTransportation.access !== 2,
        },
        disabled: isReadOnly,
        template: (templateData, itemElement) =>
          this.uploadTemplate(
            itemElement,
            updateTask,
            e.appointmentData,
            'nfTransportation',
            fieldsPermissions?.NFTransportation,
            setUploadFileType,
            setUploadFileModalOpen
          ),
        label: {
          text: 'NF Transporte',
        },
      },
    ];
  }

  static generateTransportFormFields(
    e,
    form,
    fieldsPermissions,
    vehicleOptions,
    driverOptions,
    transportOptions
  ) {
    let vehicleListVisible = false;
    let driverListVisible = false;
    let vehicleFormList = [];
    let driverFormList = [];
    let vehicleFormListValue = [];
    let driverFormListValue = [];

    const filterOptionsByCompany = (company) => {
      const companyVehicleIdList = company?.vehicleList?.map(({ id }) => id);
      const companyDriverIdList = company?.driverList?.map(({ id }) => id);

      if (fieldsPermissions?.VehicleList.access !== 0) {
        const filteredVehicleOptions = vehicleOptions?.filter(({ id }) =>
          companyVehicleIdList?.includes(id)
        );

        vehicleFormList = filteredVehicleOptions;
        vehicleListVisible = true;
        form.itemOption('transportInformation.vehicleFormList', {
          editorOptions: { dataSource: vehicleFormList, value: [] },
          visible: true,
        });
      }

      if (fieldsPermissions?.DriverList.access !== 0) {
        const filteredDriverOptions = driverOptions?.filter(({ id }) =>
          companyDriverIdList?.includes(id)
        );

        driverFormList = filteredDriverOptions;
        driverListVisible = true;
        form.itemOption('transportInformation.driverFormList', {
          editorOptions: { dataSource: driverFormList, value: [] },
          visible: true,
        });
      }
    };

    const { transportCompany } = e.appointmentData;
    if (transportCompany?.id) {
      const company = transportOptions?.find(({ id }) => id === transportCompany.id);
      filterOptionsByCompany(company);

      const { vehicleList } = e.appointmentData;
      if (vehicleList?.length > 0) {
        const mappedValues = vehicleList.map(({ id }) => id);

        vehicleFormListValue = mappedValues;

        form.option('formData.vehicleList', mappedValues);
      }

      const { driverList } = e.appointmentData;
      if (driverList?.length > 0) {
        const mappedValues = driverList.map(({ userId }) => userId);

        driverFormListValue = mappedValues;
        form.option('formData.driverList', mappedValues);
        form.option('formData.driverUserIdList', mappedValues);
      }
    }

    return [
      // Campo Empresa Transporte
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TransportCompany.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TransportCompany.access !== 0,
        editorOptions: {
          dataSource: transportOptions,
          displayExpr: 'name',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.TransportCompany.access !== 2,
          onValueChanged: (e) => {
            const selectedCompanyId = e.value;

            form.option('formData.driverFormList', []);
            form.option('formData.driverUserIdList', []);
            form.option('formData.vehicleFormList', []);

            if (selectedCompanyId) {
              const company = transportOptions.find(({ id }) => id === selectedCompanyId);
              filterOptionsByCompany(company);
            }
          },
        },
        dataField: 'transportCompany.id',
        editorType: 'dxSelectBox',
        label: {
          text: 'Empresa Transporte',
        },
      },
      // Campo Veículos
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.VehicleList.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: vehicleListVisible,
        editorOptions: {
          dataSource: vehicleFormList,
          value: vehicleFormListValue,
          displayExpr: 'label',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.VehicleList.access !== 2,
          showSelectionControls: true,
          onValueChanged: (e) => {
            const selectedItems = e.value;

            form.option('formData.vehicleList', selectedItems);
          },
        },
        dataField: 'vehicleFormList',
        editorType: 'dxTagBox',
        label: {
          text: 'Veículos',
        },
      },
      // Campo Motorista
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.DriverList.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: driverListVisible,
        editorOptions: {
          dataSource: driverFormList,
          value: driverFormListValue,
          displayExpr: 'name',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.DriverList.access !== 2,
          showSelectionControls: true,
          onValueChanged: (e) => {
            const selectedItems = e.value;

            form.option('formData.driverList', selectedItems);
            form.option('formData.driverUserIdList', selectedItems);
          },
        },
        dataField: 'driverFormList',
        editorType: 'dxTagBox',
        label: {
          text: 'Motoristas',
        },
      },
      // Campo Horário de Embarque
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.BoardingHour.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.BoardingHour.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.BoardingHour.access !== 2,
          type: 'time',
          showClearButton: true,
          openOnFieldClick: true,
        },
        dataField: 'boardingHour',
        editorType: 'dxDateBox',
        label: {
          text: 'Horário de Embarque',
        },
      },
    ];
  }

  static generateFinanceFormFields(
    e,
    fieldsPermissions,
    updateTask,
    setUploadFileType,
    setUploadFileModalOpen,
    reasonNoBillingOptions,
    trainingCenterOptions
  ) {
    const { isReadOnly } = e.appointmentData;
    return [
      // Valor de Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TrainingValue.isRequired) {
                return value !== '';
              }
              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TrainingValue.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.TrainingValue.access !== 2,
          format: {
            type: 'currency',
            currency: 'BRL',
            precision: 2,
          },
          showClearButton: true,
        },
        dataField: 'trainingValue',
        editorType: 'dxNumberBox',
        label: {
          text: 'Valor de Treinamento',
        },
      },
      // Data de Previsão de Faturamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.BillingDeadlineDate.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.BillingDeadlineDate.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.BillingDeadlineDate.access !== 2,
          type: 'date',
        },
        dataField: 'billingDeadlineDate',
        editorType: 'dxDateBox',
        label: {
          text: 'Data de Previsão de Faturamento',
        },
      },
      // Razão de não Faturamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.ReasonNoBilling.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.ReasonNoBilling.access !== 0,
        editorOptions: {
          dataSource: reasonNoBillingOptions,
          displayExpr: 'name',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.ReasonNoBilling.access !== 2,
        },
        dataField: 'reasonNoBilling.id',
        editorType: 'dxSelectBox',
        label: {
          text: 'Razão de não Faturamento',
        },
      },
      // NF Treinamento
      {
        visible: fieldsPermissions?.NFTraining.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.NFTraining.access !== 2,
        },
        disabled: isReadOnly,
        template: (templateData, itemElement) =>
          this.uploadTemplate(
            itemElement,
            updateTask,
            e.appointmentData,
            'nfTraining',
            fieldsPermissions?.NFTraining,
            setUploadFileType,
            setUploadFileModalOpen
          ),
        label: {
          text: 'NF Treinamento',
        },
      },
      // Número Nota Fiscal
      {
        dataField: 'invoiceNumber',
        editorType: 'dxTextBox',
        label: { text: 'Número Nota Fiscal' },
        visible: fieldsPermissions?.InvoiceNumber.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.InvoiceNumber.access !== 2,
        },
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) =>
              fieldsPermissions?.InvoiceNumber.isRequired ? value !== '' : true,
            message: 'Número Nota Fiscal',
          },
        ],
      },
      // Data de Emissão da Nota Fiscal
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.InvoiceEmissionDate.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.InvoiceEmissionDate.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.InvoiceEmissionDate.access !== 2,
          type: 'date',
        },
        dataField: 'invoiceEmissionDate',
        editorType: 'dxDateBox',
        label: {
          text: 'Data de Emissão da Nota Fiscal',
        },
      },
      // Emissor Nota Fiscal
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.InvoiceIssuer.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.InvoiceIssuer.access !== 0,
        editorOptions: {
          dataSource: trainingCenterOptions,
          displayExpr: 'commercialName',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.InvoiceIssuer.access !== 2,
        },
        dataField: 'invoiceIssuer.id',
        editorType: 'dxSelectBox',
        label: {
          text: 'Emissor Nota Fiscal',
        },
      },
      // Valot Nota Fiscal Total
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TotalInvoiceValue.isRequired) {
                return value !== '';
              }
              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TotalInvoiceValue.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.TotalInvoiceValue.access !== 2,
          format: {
            type: 'currency',
            currency: 'BRL',
            precision: 2,
          },
          showClearButton: true,
        },
        dataField: 'totalInvoiceValue',
        editorType: 'dxNumberBox',
        label: {
          text: 'Valor Nota Fiscal Total',
        },
      },
      // Valor Nota Fiscal Pascial
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.PartialInvoiceValue.isRequired) {
                return value !== '';
              }
              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.PartialInvoiceValue.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.PartialInvoiceValue.access !== 2,
          format: {
            type: 'currency',
            currency: 'BRL',
            precision: 2,
          },
          showClearButton: true,
        },
        dataField: 'partialInvoiceValue',
        editorType: 'dxNumberBox',
        label: {
          text: 'Valor Nota Fiscal Parcial',
        },
      },
    ];
  }

  static generateCertificateFormFields(
    e,
    fieldsPermissions,
    setIsCertificateSettingsModalOpen,
    setIsUploadStudentsModalOpen,
    setIsGenerateCertificateModalOpen
  ) {
    const stageId = e.appointmentData.stage?.id;
    const invalidStages = [
      1, // 1 = Aguardando
      6, // 6 = Reagendar
      11, // 11 = Distrato
      3, // 3 = Confirmado
      4, // 4 = Negado
      6, // 6 = Reagendar
      10, // 10 = Pré-Reservado
      12, // 12 = Reservado
      13, // 13 = Reservado sem confirmação
    ];
    const isValidStage = !invalidStages.includes(stageId);

    const { isReadOnly, certificateModel, studentList, queryStudentList } = e.appointmentData;
    const isReady = certificateModel && studentList?.length > 0 && queryStudentList?.length > 0;
    const canHandleImportStudents = fieldsPermissions?.ImportStudentListBtn.access === 2;
    const canHandleSettings = fieldsPermissions?.CertificateTrainingSettingsBtn.access === 2;
    const canHandleGenerate = fieldsPermissions?.GenerateCertificateBtn.access === 2;

    return [
      // Não Gera Certificado
      {
        visible: fieldsPermissions?.NotHaveCertificate.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.NotHaveCertificate.access !== 2,
        },
        dataField: 'notHaveCertificate',
        editorType: 'dxCheckBox',
        label: {
          text: 'Não Gera Certificado',
        },
      },

      // Importar Lista de Alunos
      {
        visible: fieldsPermissions?.ImportStudentListBtn.access !== 0,
        template: (templateData, itemElement) => {
          const container = document.createElement('div');
          itemElement.append(container);

          const root = createRoot(container);
          root.render(
            <Button
              disabled={isReadOnly || !canHandleImportStudents || !isValidStage}
              onClick={() => {
                setIsUploadStudentsModalOpen(true);
              }}
              type="default"
              icon={<SnippetsOutlined />}
            >
              Importar Lista de Alunos
            </Button>
          );
        },
      },

      // Configurar Certificado
      {
        visible: fieldsPermissions?.CertificateTrainingSettingsBtn.access !== 0,
        template: (templateData, itemElement) => {
          const container = document.createElement('div');
          itemElement.append(container);

          const root = createRoot(container);
          root.render(
            <Button
              disabled={isReadOnly || !canHandleSettings || !isValidStage}
              onClick={() => {
                setIsCertificateSettingsModalOpen(true);
              }}
              type="default"
              icon={<SettingOutlined />}
            >
              Configurar Certificado
            </Button>
          );
        },
      },

      // Gerar certificado
      {
        visible: fieldsPermissions?.GenerateCertificateBtn.access !== 0,
        template: (templateData, itemElement) => {
          const container = document.createElement('div');
          itemElement.append(container);

          const root = createRoot(container);
          root.render(
            <Button
              disabled={isReadOnly || !canHandleGenerate || !isReady || !isValidStage}
              onClick={() => {
                setIsGenerateCertificateModalOpen(true);
              }}
              type="default"
              icon={<FilePdfOutlined />}
            >
              Gerar Certificado
            </Button>
          );
        },
      },
    ];
  }

  static generatePopupFieldsInstructor(
    mainGroupItems,
    fieldsPermissions,
    stages,
    trainingsOptions,
    scheduleType,
    editingTask,
    showData
  ) {
    let formItems = mainGroupItems;
    formItems = [
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Product.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Product.access !== 0 && showData,
        editorOptions: {
          disabled: fieldsPermissions?.Product.access !== 2,
        },
        dataField: 'text',
        editorType: 'dxTextBox',
        label: {
          text: 'Produto',
        },
      },
      // Campo Estágio
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Stage.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Stage.access !== 0 && showData,
        editorOptions: {
          dataSource: stages,
          displayExpr: 'name',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.Stage.access !== 2,
        },
        dataField: `${scheduleType}.stage.id`,
        editorType: 'dxSelectBox',
        label: {
          text: 'Estágio',
        },
      },
      // Campo Data
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Date.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Date.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.Date.access !== 2,
          type: 'date',
        },
        dataField: 'startDate',
        editorType: 'dxDateBox',
        label: {
          text: 'Data',
        },
      },
      // Campo Horário Início
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.StartHour.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.StartHour.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.StartHour.access !== 2,
          type: 'time',
        },
        dataField: 'startHour',
        editorType: 'dxDateBox',
        label: {
          text: 'Horário Início',
        },
      },
      // Campo Horário Fim
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.EndHour.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.EndHour.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.EndHour.access !== 2,
          type: 'time',
        },
        dataField: 'endHour',
        editorType: 'dxDateBox',
        label: {
          text: 'Horário Fim',
        },
      },
      // Campo Início Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TrainingStart.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TrainingStart.access !== 0 && showData,
        editorOptions: {
          disabled: !editingTask || fieldsPermissions?.TrainingStart.access !== 2,
          type: 'datetime',
        },
        dataField: `${scheduleType}.startDate`,
        editorType: 'dxDateBox',
        label: {
          text: 'Início Treinamento',
        },
      },
      // Campo Fim Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TrainingEnd.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TrainingEnd.access !== 0 && showData,
        editorOptions: {
          disabled: !editingTask || fieldsPermissions?.TrainingEnd.access !== 2,
          type: 'datetime',
          with: '70%',
        },
        dataField: `${scheduleType}.endDate`,
        editorType: 'dxDateBox',
        label: {
          text: 'Fim Treinamento',
        },
      },
      // Campo Grupo de Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TrainingGroup.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TrainingGroup.access !== 0 && showData,
        editorOptions: {
          dataSource: trainingsOptions,
          displayExpr: 'name',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.TrainingGroup.access !== 2,
        },
        dataField: 'group.id',
        editorType: 'dxSelectBox',
        label: {
          text: 'Grupo de Treinamento',
        },
      },
      // Campo Centro de Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TrainingCenter.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TrainingCenter.access !== 0 && showData,
        editorOptions: {
          disabled: fieldsPermissions?.TrainingCenter.access !== 2,
        },
        dataField: 'trainingCenter',
        editorType: 'dxTextBox',
        label: {
          text: 'Centro de Treinamento',
        },
      },

      // // Campo Duração Prevista
      // {
      //   validationRules: [
      //     {
      //       type: 'custom',
      //       validationCallback: ({ value }) => {
      //         if (fieldsPermissions?.ExpctedDuration.isRequired) {
      //           return value !== '';
      //         }

      //         return true;
      //       },
      //       message: 'Campo obrigatório',
      //     },
      //   ],
      //   visible: fieldsPermissions?.ExpctedDuration.access !== 0 && showData,
      //   editorOptions: {
      //     disabled: fieldsPermissions?.ExpctedDuration.access !== 2,
      //   },
      //   dataField: 'durationMinutes',
      //   editorType: 'dxTextBox',
      //   label: {
      //     text: 'Duração Prevista (Min)',
      //   },
      // },
      // // Campo Duração Realizada
      // {
      //   validationRules: [
      //     {
      //       type: 'custom',
      //       validationCallback: ({ value }) => {
      //         if (fieldsPermissions?.HeldDuration.isRequired) {
      //           return value !== '';
      //         }

      //         return true;
      //       },
      //       message: 'Campo obrigatório',
      //     },
      //   ],
      //   visible: fieldsPermissions?.HeldDuration.access !== 0 && showData,
      //   editorOptions: {
      //     disabled: fieldsPermissions?.HeldDuration.access !== 2,
      //   },
      //   dataField: `${scheduleType}.totalTrainingTime`,
      //   editorType: 'dxNumberBox',
      //   label: {
      //     text: 'Duração Realizada (Min)',
      //   },
      // },
      // Campo Quantidade Alunos Treinados
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.StudentsTrained.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.StudentsTrained.access !== 0 && showData,
        editorOptions: {
          disabled: !editingTask || fieldsPermissions?.StudentsTrained.access !== 2,
        },
        dataField: 'qtyStudentsTrained',
        editorType: 'dxTextBox',
        label: {
          text: 'Quantidade Alunos Treinados',
        },
      },
      // Campo Observações
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Notes.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Notes.access !== 0 && showData,
        editorOptions: {
          disabled: fieldsPermissions?.Notes.access !== 2,
          height: 120,
        },
        colSpan: 2,
        dataField: 'note',
        editorType: 'dxTextArea',
        label: {
          text: 'Obervações',
        },
      },
      // Campo Observação Instrutor
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.InstructorNotes.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.InstructorNotes.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.InstructorNotes.access !== 2,
          height: 120,
        },
        dataField: 'instructorNotes',
        editorType: 'dxTextArea',
        label: {
          text: 'Observação',
        },
      },
    ];
    return formItems;
  }

  static generatePopupFieldsDriver(
    mainGroupItems,
    fieldsPermissions,
    stages,
    trainingsOptions,
    editingTask
  ) {
    let formItems = mainGroupItems;
    formItems = [
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Product.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Product.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.Product.access !== 2,
        },
        dataField: 'text',
        editorType: 'dxTextBox',
        label: {
          text: 'Produto',
        },
      },
      // Campo Estágio
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Stage.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Stage.access !== 0,
        editorOptions: {
          dataSource: stages,
          displayExpr: 'name',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.Stage.access !== 2,
        },
        dataField: 'driver.stage.id',
        editorType: 'dxSelectBox',
        label: {
          text: 'Estágio',
        },
      },
      // Campo Data
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Date.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Date.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.Date.access !== 2,
          type: 'date',
        },
        dataField: 'startDate',
        editorType: 'dxDateBox',
        label: {
          text: 'Data',
        },
      },
      // Campo Horário Início
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.StartHour.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.StartHour.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.StartHour.access !== 2,
          type: 'time',
          showClearButton: true,
          openOnFieldClick: true,
        },
        dataField: 'startHour',
        editorType: 'dxDateBox',
        label: {
          text: 'Horário Início',
        },
      },
      // Campo Horário Fim
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.EndHour.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.EndHour.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.EndHour.access !== 2,
          type: 'time',
          showClearButton: true,
          openOnFieldClick: true,
        },
        dataField: 'endHour',
        editorType: 'dxDateBox',
        label: {
          text: 'Horário Fim',
        },
      },
      // // Campo Início Treinamento
      // {
      //   validationRules: [
      //     {
      //       type: 'custom',
      //       validationCallback: ({ value }) => {
      //         if (fieldsPermissions?.TrainingStart.isRequired) {
      //           return value !== '';
      //         }

      //         return true;
      //       },
      //       message: 'Campo obrigatório',
      //     },
      //   ],
      //   visible: fieldsPermissions?.TrainingStart.access !== 0,
      //   editorOptions: {
      //     disabled: !editingTask || fieldsPermissions?.TrainingStart.access !== 2,
      //     type: 'datetime',
      //   },
      //   dataField: 'driver.startDate',
      //   editorType: 'dxDateBox',
      //   label: {
      //     text: 'Início Treinamento',
      //   },
      // },
      // // Campo Fim Treinamento
      // {
      //   validationRules: [
      //     {
      //       type: 'custom',
      //       validationCallback: ({ value }) => {
      //         if (fieldsPermissions?.TrainingEnd.isRequired) {
      //           return value !== '';
      //         }

      //         return true;
      //       },
      //       message: 'Campo obrigatório',
      //     },
      //   ],
      //   visible: fieldsPermissions?.TrainingEnd.access !== 0,
      //   editorOptions: {
      //     disabled: !editingTask || fieldsPermissions?.TrainingEnd.access !== 2,
      //     type: 'datetime',
      //   },
      //   dataField: 'driver.endDate',
      //   editorType: 'dxDateBox',
      //   label: {
      //     text: 'Fim Treinamento',
      //   },
      // },
      // Campo Grupo de Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Group.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Group.access !== 0,
        editorOptions: {
          dataSource: trainingsOptions,
          displayExpr: 'name',
          valueExpr: 'id',
          showClearButton: true,
          searchEnabled: true,
          disabled: fieldsPermissions?.Group.access !== 2,
        },
        dataField: 'group.id',
        editorType: 'dxSelectBox',
        label: {
          text: 'Grupo de Treinamento',
        },
      },
      // Campo Centro de Treinamento
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.TrainingCenter.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.TrainingCenter.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.TrainingCenter.access !== 2,
        },
        dataField: 'trainingCenter',
        editorType: 'dxTextBox',
        label: {
          text: 'Centro de Treinamento',
        },
      },

      // // Campo Duração Prevista
      // {
      //   validationRules: [
      //     {
      //       type: 'custom',
      //       validationCallback: ({ value }) => {
      //         if (fieldsPermissions?.ExpctedDuration.isRequired) {
      //           return value !== '';
      //         }

      //         return true;
      //       },
      //       message: 'Campo obrigatório',
      //     },
      //   ],
      //   visible: fieldsPermissions?.ExpctedDuration.access !== 0,
      //   editorOptions: {
      //     disabled: fieldsPermissions?.ExpctedDuration.access !== 2,
      //   },
      //   dataField: 'durationMinutes',
      //   editorType: 'dxTextBox',
      //   label: {
      //     text: 'Duração Prevista (Min)',
      //   },
      // },
      // // Campo Duração Realizada
      // {
      //   validationRules: [
      //     {
      //       type: 'custom',
      //       validationCallback: ({ value }) => {
      //         if (fieldsPermissions?.HeldDuration.isRequired) {
      //           return value !== '';
      //         }

      //         return true;
      //       },
      //       message: 'Campo obrigatório',
      //     },
      //   ],
      //   visible: fieldsPermissions?.HeldDuration.access !== 0,
      //   editorOptions: {
      //     disabled: fieldsPermissions?.HeldDuration.access !== 2,
      //   },
      //   dataField: 'driver.totalTrainingTime',
      //   editorType: 'dxNumberBox',
      //   label: {
      //     text: 'Duração Realizada (Min)',
      //   },
      // },
      // Campo Alunos Transportados
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.StudentsTrained.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.StudentsTrained.access !== 0,
        editorOptions: {
          disabled: !editingTask || fieldsPermissions?.StudentsTrained.access !== 2,
        },
        dataField: 'qtyStudentsTransported',
        editorType: 'dxTextBox',
        label: {
          text: 'Alunos Transportados',
        },
      },
      // Campo Observações
      {
        validationRules: [
          {
            type: 'custom',
            validationCallback: ({ value }) => {
              if (fieldsPermissions?.Note.isRequired) {
                return value !== '';
              }

              return true;
            },
            message: 'Campo obrigatório',
          },
        ],
        visible: fieldsPermissions?.Note.access !== 0,
        editorOptions: {
          disabled: fieldsPermissions?.Note.access !== 2,
          height: 120,
        },
        colSpan: 2,
        dataField: 'note',
        editorType: 'dxTextArea',
        label: {
          text: 'Obervações',
        },
      },
    ];
    return formItems;
  }

  static async deleteAttachmentFromTask(docName, taskId) {
    await deleteFileDocuments(docName, 'trainings', taskId);
  }
}
